import { Module, ActionContext, ActionTree, MutationTree, Store } from 'vuex'
import { RootState } from '~/store'
import { LogErrorAndToastT } from '~/plugins/helpers/error'
import {
  GraphqlError,
  GraphqlMutationResponse,
  GraphqlQueryResponse
} from '~/types/apollo-graphql-types'
import {
  Team,
  TeamMember,
  TeamMemberRoleChoices,
  TransferTeamOwnershipInput,
  UpdateTeamBasePermissionsInput,
  UpdateTeamBasePermissionsPayload
} from '~/types/types'
import { resolveNodes } from '~/utils/array'

// Queries
import InvitedUsersListQuery from '~/apollo/queries/team/invites.gql'
import TeamInviteUrlQuery from '~/apollo/queries/team/inviteUrl.gql'
import TeamMembersListQuery from '~/apollo/queries/team/list.gql'
import TeamSettings from '~/apollo/queries/team/settings.gql'

// Mutations
import inviteMembers from '~/apollo/mutations/team/inviteMembers.gql'
import inviteSingle from '~/apollo/mutations/team/inviteSingle.gql'
import removeMember from '~/apollo/mutations/team/removeMember.gql'
import resetTeamInviteLink from '~/apollo/mutations/team/resetTeamInviteLink.gql'
import SyncVcsPermissions from '~/apollo/mutations/team/syncVcsPermissions.gql'
import transferOwnership from '~/apollo/mutations/team/transferOwnership.gql'
import UpdateAccessControlSettings from '~/apollo/mutations/team/updateAccessControlSettings.gql'
import updateRole from '~/apollo/mutations/team/updateRole.gql'
import UpdateTeamBasePermissions from '~/apollo/mutations/team/updateTeamBasePermissions.gql'

export interface TeamState {
  loading: boolean
  error: Record<string, unknown>
  team: Team
}

export enum TeamMutations {
  SET_LOADING = 'setTeamActionsLoading',
  SET_ERROR = 'setTeamActionsError',
  SET_TEAM = 'setTeam'
}

export enum TeamActions {
  FETCH_TEAM_INFO = 'fetchTeamInfo',
  FETCH_TEAM_SETTINGS = 'fetchTeamSettings',
  QUERY_TEAM_MEMBERS = 'queryTeamMembers',
  FETCH_INVITED_USERS = 'fetchInvitedUsers',
  INVITE_MEMBERS = 'inviteMembers',
  INVITE_SINGLE = 'inviteSingle',
  CANCEL_INVITE = 'cancelInvite',
  REMOVE_MEMBER = 'removeMember',
  UPDATE_MEMBER_ROLE = 'updateMemberRole',
  TRANSFER_OWNERSHIP = 'transferOwnership',
  FETCH_INVITE_LINK = 'fetchInviteLink',
  RESET_INVITE_LINK = 'resetInviteLink',
  UPDATE_ACCESS_CONTROL_SETTINGS = 'updateAccessControlSettings',
  UPDATE_TEAM_BASE_PERMS = 'updateTeamBasePermissions',
  SYNC_VCS_PERMISSIONS = 'syncVcsPermissions'
}

export interface TeamModuleMutations extends MutationTree<TeamState> {
  [TeamMutations.SET_LOADING](state: TeamState, value: boolean): void
  [TeamMutations.SET_ERROR](state: TeamState, error: GraphqlError): void
  [TeamMutations.SET_TEAM](state: TeamState, team: Team): void
}

export type TeamActionContext = ActionContext<TeamState, RootState>

export interface TeamModuleActions extends ActionTree<TeamState, RootState> {
  [TeamActions.FETCH_TEAM_INFO](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      login,
      provider,
      limit,
      currentPage,
      query,
      refetch
    }: {
      login: string
      provider: string
      limit: number
      currentPage: number
      query: string
      refetch?: boolean
    }
  ): Promise<void>

  [TeamActions.FETCH_TEAM_SETTINGS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      login,
      provider,
      refetch
    }: {
      login: string
      provider: string
      refetch?: boolean
    }
  ): Promise<void>

  [TeamActions.QUERY_TEAM_MEMBERS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      login,
      provider,
      limit,
      currentPage,
      query,
      refetch
    }: {
      login: string
      provider: string
      limit: number
      currentPage: number
      query: string
      refetch?: boolean
    }
  ): Promise<Array<TeamMember> | undefined>

  [TeamActions.FETCH_INVITED_USERS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      login,
      provider,
      limit,
      currentPage,
      q
    }: {
      login: string
      provider: string
      limit: number
      currentPage: number
      q?: string
    }
  ): Promise<void>

  [TeamActions.UPDATE_MEMBER_ROLE](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    args: {
      ownerId: string
      email: string
      role: string
    }
  ): Promise<void>

  [TeamActions.REMOVE_MEMBER](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    args: {
      ownerId: string
      email: string
    }
  ): Promise<void>

  [TeamActions.TRANSFER_OWNERSHIP](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    args: TransferTeamOwnershipInput
  ): Promise<boolean>

  [TeamActions.INVITE_MEMBERS](
    this: Store<RootState>,
    { commit, dispatch }: TeamActionContext,
    {
      ownerId,
      invitees,
      ...refetchArgs
    }: {
      ownerId: string
      invitees: string
      login: string
      provider: string
      limit: number
      currentPage: number
    }
  ): Promise<void>

  [TeamActions.FETCH_INVITE_LINK](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      login,
      provider,
      refetch
    }: {
      login: string
      provider: string
      refetch?: boolean
    }
  ): Promise<void>

  [TeamActions.RESET_INVITE_LINK](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    { ownerId }: { ownerId: string }
  ): Promise<void>

  [TeamActions.UPDATE_ACCESS_CONTROL_SETTINGS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      teamId,
      syncPermissionsWithVcs
    }: {
      teamId: string
      syncPermissionsWithVcs: boolean
    }
  ): Promise<boolean>

  [TeamActions.UPDATE_TEAM_BASE_PERMS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    args: UpdateTeamBasePermissionsInput
  ): Promise<UpdateTeamBasePermissionsPayload | undefined>

  [TeamActions.SYNC_VCS_PERMISSIONS](
    this: Store<RootState>,
    { commit }: TeamActionContext,
    {
      teamId,
      overrideChangesMadeOnDeepsource
    }: {
      teamId: string
      overrideChangesMadeOnDeepsource: boolean
    }
  ): Promise<void>

  [TeamActions.INVITE_SINGLE](
    this: Store<RootState>,
    { commit, dispatch }: TeamActionContext,
    {
      ownerId,
      role,
      email,
      ...refetchArgs
    }: {
      ownerId: string
      role: TeamMemberRoleChoices
      email: string
      login: string
      provider: string
      limit: number
      currentPage: number
    }
  ): Promise<void>

  [TeamActions.CANCEL_INVITE](
    this: Store<RootState>,
    { commit, dispatch }: TeamActionContext,
    {
      ownerId,
      email,
      ...refetchArgs
    }: {
      ownerId: string
      email: string
      login: string
      provider: string
      limit: number
      currentPage: number
    }
  ): Promise<void>
}

const teamStoreModule: Module<TeamState, RootState> = {
  state: (): TeamState => ({
    loading: false,
    error: {},
    team: {} as Team
  }),

  mutations: {
    [TeamMutations.SET_LOADING](state, value) {
      state.loading = value
    },
    [TeamMutations.SET_ERROR](state, error) {
      state.error = Object.assign({}, state.error, error)
    },
    [TeamMutations.SET_TEAM](state, team) {
      state.team = Object.assign({}, state.team, team)
    }
  } as TeamModuleMutations,

  actions: {
    async [TeamActions.FETCH_TEAM_INFO](
      { commit },
      { login, provider, limit, currentPage, query, refetch }
    ) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          TeamMembersListQuery,
          {
            provider: this.$providerMetaMap[provider].value,
            after: this.$getGQLAfter(currentPage, limit),
            limit,
            login,
            query
          },
          refetch
        )
        commit(TeamMutations.SET_TEAM, response.data.team)
        commit(TeamMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.FETCH_TEAM_SETTINGS]({ commit }, { login, provider, refetch }) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          TeamSettings,
          {
            provider: this.$providerMetaMap[provider].value,
            login
          },
          refetch
        )
        commit(TeamMutations.SET_TEAM, response.data.team)
        commit(TeamMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.QUERY_TEAM_MEMBERS](
      _,
      { login, provider, limit, currentPage, query, refetch }
    ) {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          TeamMembersListQuery,
          {
            provider: this.$providerMetaMap[provider].value,
            after: this.$getGQLAfter(currentPage, limit),
            limit,
            login,
            query
          },
          refetch
        )
        return resolveNodes(response.data.team?.teamMembers)
      } catch (e) {
        this.$logErrorAndToast(
          e as Error,
          (e as Error).message.replace('GraphQL error: ', '') as Parameters<LogErrorAndToastT>['1']
        )
      }
      return undefined
    },

    async [TeamActions.FETCH_INVITED_USERS](
      { commit },
      { login, provider, limit, currentPage, q }
    ) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(InvitedUsersListQuery, {
          provider: this.$providerMetaMap[provider].value,
          after: this.$getGQLAfter(currentPage, limit),
          login,
          limit,
          q
        })
        commit(TeamMutations.SET_TEAM, response.data.team)
        commit(TeamMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.UPDATE_MEMBER_ROLE]({ commit }, args) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        await this.$applyGraphqlMutation(updateRole, {
          ownerPk: args.ownerId,
          email: args.email,
          role: args.role
        })
      } catch (e) {
        this.$toast.show({
          type: 'danger',
          message: (e as Error).message.replace('GraphQL error: ', ''),
          timeout: 5
        })
        commit(TeamMutations.SET_ERROR, e)
      } finally {
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.REMOVE_MEMBER]({ commit }, args) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response = (await this.$applyGraphqlMutation(removeMember, {
          ownerPk: args.ownerId,
          email: args.email
        })) as GraphqlMutationResponse
        if (response.data.removeTeamMember?.ok)
          this.$toast.success('Team member removed successfully.')
      } catch (e) {
        const err = e as Error
        const errMsg = `${err.message.replace('GraphQL error: ', '')}.` as `${string}.`
        this.$logErrorAndToast(err, errMsg)
        commit(TeamMutations.SET_ERROR, e)
      } finally {
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.TRANSFER_OWNERSHIP](_, args) {
      try {
        const response = (await this.$applyGraphqlMutation(transferOwnership, {
          input: args
        })) as GraphqlMutationResponse
        return Boolean(response.data.transferTeamOwnership?.ok)
      } catch (e) {
        this.$logErrorAndToast(
          e as Error,
          (e as Error).message.replace('GraphQL error: ', '') as Parameters<LogErrorAndToastT>['1']
        )
        return false
      }
    },

    async [TeamActions.INVITE_MEMBERS](
      { commit, dispatch },
      { ownerId, invitees, ...refetchArgs }
    ) {
      commit(TeamMutations.SET_LOADING, true)
      const mutationArgs = { ownerId, invitees, action: 'CREATE' }

      const refetchParams = {
        provider: this.$providerMetaMap[refetchArgs.provider].value,
        after: this.$getGQLAfter(refetchArgs.currentPage, refetchArgs.limit),
        limit: refetchArgs.limit,
        login: refetchArgs.login
      }

      await this.$applyGraphqlMutation(inviteMembers, { input: mutationArgs })
      await this.$fetchGraphqlData(InvitedUsersListQuery, refetchParams, true)
      await dispatch(TeamActions.FETCH_INVITED_USERS, refetchParams)
    },

    async [TeamActions.FETCH_INVITE_LINK]({ commit }, { login, provider, refetch }) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          TeamInviteUrlQuery,
          {
            provider: this.$providerMetaMap[provider].value,
            login
          },
          refetch
        )
        commit(TeamMutations.SET_TEAM, response.data.owner?.team)
        commit(TeamMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.RESET_INVITE_LINK]({ commit }, { ownerId }) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response = await this.$applyGraphqlMutation(resetTeamInviteLink, {
          input: { ownerId }
        })
        commit(TeamMutations.SET_TEAM, response.data.resetInvitationLink)
        commit(TeamMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.UPDATE_ACCESS_CONTROL_SETTINGS](
      { commit },
      { teamId, syncPermissionsWithVcs }
    ) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response: GraphqlMutationResponse = await this.$applyGraphqlMutation(
          UpdateAccessControlSettings,
          {
            teamId,
            syncPermissionsWithVcs
          }
        )
        commit(TeamMutations.SET_LOADING, false)
        return Boolean(response.data.updateAccessControlSettings?.ok)
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
      return false
    },

    async [TeamActions.UPDATE_TEAM_BASE_PERMS]({ commit }, args) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const response = await this.$applyGraphqlMutation(UpdateTeamBasePermissions, {
          input: args
        })
        commit(TeamMutations.SET_LOADING, false)
        return response.data?.updateTeamBasePermissions
      } catch (e) {
        const error = e as GraphqlError
        commit(TeamMutations.SET_ERROR, error)
        commit(TeamMutations.SET_LOADING, false)
      }
      return undefined
    },

    async [TeamActions.SYNC_VCS_PERMISSIONS](
      { commit },
      { teamId, overrideChangesMadeOnDeepsource }
    ) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        await this.$applyGraphqlMutation(SyncVcsPermissions, {
          teamId,
          overrideChangesMadeOnDeepsource
        })
        this.$toast.success('Successfully synced permissions.')
      } catch (e) {
        const error = e as Error
        this.$logErrorAndToast(error, 'Unable to sync permissions. Please contact support.')
        commit(TeamMutations.SET_ERROR, error)
      } finally {
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.INVITE_SINGLE](
      { commit, dispatch },
      { ownerId, role, email, ...refetchArgs }
    ) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const refetchParams = {
          provider: this.$providerMetaMap[refetchArgs.provider].value,
          after: this.$getGQLAfter(refetchArgs.currentPage, refetchArgs.limit),
          limit: refetchArgs.limit,
          login: refetchArgs.login
        }

        await this.$applyGraphqlMutation(inviteSingle, { ownerId, email, role, action: 'CREATE' })
        await this.$fetchGraphqlData(InvitedUsersListQuery, refetchParams, true)
        await dispatch(TeamActions.FETCH_INVITED_USERS, refetchParams)
      } catch (e) {
        commit(TeamMutations.SET_ERROR, e)
      } finally {
        commit(TeamMutations.SET_LOADING, false)
      }
    },

    async [TeamActions.CANCEL_INVITE]({ commit, dispatch }, { ownerId, email, ...refetchArgs }) {
      try {
        commit(TeamMutations.SET_LOADING, true)
        const role = TeamMemberRoleChoices.Contributor
        const action = 'CANCEL'

        const refetchParams = {
          provider: this.$providerMetaMap[refetchArgs.provider].value,
          after: this.$getGQLAfter(refetchArgs.currentPage, refetchArgs.limit),
          limit: refetchArgs.limit,
          login: refetchArgs.login
        }

        await this.$applyGraphqlMutation(inviteSingle, { ownerId, email, role, action })
        await this.$fetchGraphqlData(InvitedUsersListQuery, refetchParams, true)
        await dispatch(TeamActions.FETCH_INVITED_USERS, refetchParams)
      } catch (e) {
        commit(TeamMutations.SET_ERROR, e)
      } finally {
        commit(TeamMutations.SET_LOADING, false)
      }
    }
  } as TeamModuleActions
}

export default teamStoreModule
