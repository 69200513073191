import { ActionContext, ActionTree, Module, MutationTree, Store } from 'vuex'
import { RootState } from '~/store'

export interface SubscriptionStore {
  selectedPlan: string
  billingCycle: 'yearly' | 'monthly'
  seats: number
}

// Mutations ----------------------------------------
export enum SubscriptionMutationTypes {
  SET_PLAN = 'setPlan',
  RESET = 'reset'
}

export interface SubscriptionModuleMutations extends MutationTree<SubscriptionStore> {
  [SubscriptionMutationTypes.SET_PLAN](state: SubscriptionStore, details: SubscriptionStore): void
  [SubscriptionMutationTypes.RESET](state: SubscriptionStore): void
}

// Actions ----------------------------------------
export type SubscriptionActionContext = ActionContext<SubscriptionStore, RootState>

export enum SubscriptionActionTypes {
  SET_PLAN = 'setPlan',
  RESET_PLAN = 'resetPlan'
}

export interface SubscriptionModuleActions extends ActionTree<SubscriptionStore, RootState> {
  [SubscriptionActionTypes.SET_PLAN](
    this: Store<RootState>,
    { commit }: SubscriptionActionContext,
    args: {
      billingCycle?: 'yearly' | 'monthly'
      plan?: string
      seat?: number
    }
  ): void
  [SubscriptionActionTypes.RESET_PLAN](
    this: Store<RootState>,
    { commit }: SubscriptionActionContext
  ): void
}

const subscriptionStoreModule: Module<SubscriptionStore, RootState> = {
  state: (): SubscriptionStore => ({
    selectedPlan: '',
    billingCycle: 'yearly',
    seats: 3
  }),

  mutations: {
    [SubscriptionMutationTypes.SET_PLAN]: (state, details) => {
      Object.assign(state, details)
    },

    [SubscriptionMutationTypes.RESET]: (state) => {
      Object.assign(state, {
        selectedPlan: '',
        billingCycle: 'yearly',
        seats: 3
      })
    }
  } as SubscriptionModuleMutations,

  actions: {
    [SubscriptionActionTypes.SET_PLAN]({ commit }, args) {
      commit(SubscriptionMutationTypes.SET_PLAN, args)
    },
    [SubscriptionActionTypes.RESET_PLAN]({ commit }) {
      commit(SubscriptionMutationTypes.RESET)
    }
  } as SubscriptionModuleActions
}

export default subscriptionStoreModule
