import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'
import AddAnalyzerFeedbackMutation from '~/apollo/mutations/directory/addAnalyzerFeedback.gql'
import AddTransformerFeedbackMutation from '~/apollo/mutations/directory/addTransformerFeedback.gql'
import AnalyzerDetailsQuery from '~/apollo/queries/analyzer/detailed.gql'
import AnalyzerIssuesQuery from '~/apollo/queries/analyzer/listIssues.gql'
import AnalyzersGQLPublicQuery from '~/apollo/queries/analyzer/publicList.gql'
import TransformersDetailsQuery from '~/apollo/queries/transformer/detailed.gql'
import TransformersGQLPublicQuery from '~/apollo/queries/transformer/publicList.gql'
import { RootState } from '~/store'
import {
  GraphqlError,
  GraphqlMutationResponse,
  GraphqlQueryResponse
} from '~/types/apollo-graphql-types'
import { IssueSeverityFilter } from '~/types/issues'
import {
  AddAnalyzerFeedbackInput,
  AddTransformerFeedbackInput,
  Analyzer,
  AnalyzerConnection,
  PageInfo,
  TransformerTool,
  TransformerToolConnection
} from '~/types/types'
import { resolveNodes } from '~/utils/array'

export enum DirectoryActions {
  FETCH_ANALYZER_DIR_LIST = 'fetchAnalyzerDirList',
  FETCH_ANALYZER_DETAILS = 'fetchAnalyzerDetails',
  FETCH_ANALYZER_ISSUES = 'fetchAnalyzerIssues',
  FETCH_TRANSFORMERS_DIR_LIST = 'fetchTransformersDirList',
  FETCH_TRANSFORMER_DETAILS = 'fetchTransformerDetails',
  ADD_ANALYZER_FEEDBACK = 'addAnalyzerFeedback',
  ADD_TRANSFORMER_FEEDBACK = 'addTransformerFeedback'
}

export enum DirectoryGetters {
  DIRECTORY_ANALYZERS = 'getDirectoryAnalyzers',
  DIRECTORY_TRANSFORMERS = 'getDirectoryTransformers'
}

export enum DirectoryMutations {
  SET_ERROR = 'setAnalyzerListError',
  SET_LOADING = 'setAnalyzerListLoading',
  SET_ANALYZER_LIST = 'setAnalyzerDirList',
  SET_TRANSFORMER_LIST = 'setTransformerDirList'
}

export interface DirectoryModuleState {
  loading: boolean
  error: GraphqlError | Record<string, unknown>
  analyzerList: AnalyzerConnection
  transformerList: TransformerToolConnection
}

export type DirectoryActionContext = ActionContext<DirectoryModuleState, RootState>

export interface DirectoryModuleGetters extends GetterTree<DirectoryModuleState, RootState> {
  [DirectoryGetters.DIRECTORY_ANALYZERS](state: DirectoryModuleState): Analyzer[]
  [DirectoryGetters.DIRECTORY_TRANSFORMERS](state: DirectoryModuleState): TransformerTool[]
}

export interface DirectoryModuleMutations extends MutationTree<DirectoryModuleState> {
  [DirectoryMutations.SET_LOADING](state: DirectoryModuleState, value: boolean): void
  [DirectoryMutations.SET_ERROR](state: DirectoryModuleState, error: GraphqlError): void
  [DirectoryMutations.SET_ANALYZER_LIST](
    state: DirectoryModuleState,
    analyzerDirList: AnalyzerConnection
  ): void
  [DirectoryMutations.SET_TRANSFORMER_LIST](
    state: DirectoryModuleState,
    transformerDirList: TransformerToolConnection
  ): void
}

export interface DirectoryModuleActions extends ActionTree<DirectoryModuleState, RootState> {
  [DirectoryActions.FETCH_ANALYZER_DIR_LIST](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    args?: { q: string }
  ): Promise<void>
  [DirectoryActions.FETCH_ANALYZER_DETAILS](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    { shortcode, first }: { shortcode: string; first?: number }
  ): Promise<Analyzer | null>
  [DirectoryActions.FETCH_ANALYZER_ISSUES](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    args: {
      shortcode: string
      first: number
      offset: number
      autofixAvailable: boolean
      q: string
      issueType: string
      severity?: IssueSeverityFilter
    }
  ): Promise<Analyzer | null>
  [DirectoryActions.FETCH_TRANSFORMERS_DIR_LIST](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    args?: { q: string }
  ): Promise<void>
  [DirectoryActions.ADD_ANALYZER_FEEDBACK](
    this: Store<RootState>,

    { commit }: DirectoryActionContext,
    { input }: { input: AddAnalyzerFeedbackInput }
  ): Promise<boolean>
  [DirectoryActions.FETCH_TRANSFORMER_DETAILS](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    args?: { shortcode: string }
  ): Promise<TransformerTool | null>
  [DirectoryActions.ADD_TRANSFORMER_FEEDBACK](
    this: Store<RootState>,
    { commit }: DirectoryActionContext,
    { input }: { input: AddTransformerFeedbackInput }
  ): Promise<boolean>
}

const directoryStoreModule: Module<DirectoryModuleState, RootState> = {
  state: (): DirectoryModuleState => ({
    loading: false,
    error: {},
    analyzerList: {
      pageInfo: {} as PageInfo,
      edges: []
    },
    transformerList: {
      pageInfo: {} as PageInfo,
      edges: []
    }
  }),

  getters: {
    [DirectoryGetters.DIRECTORY_ANALYZERS]: (state) => {
      return resolveNodes(state.analyzerList)
    },
    [DirectoryGetters.DIRECTORY_TRANSFORMERS]: (state) => {
      return resolveNodes(state.transformerList)
    }
  } as DirectoryModuleGetters,

  mutations: {
    [DirectoryMutations.SET_LOADING](state, value) {
      state.loading = value
    },
    [DirectoryMutations.SET_ERROR](state, error) {
      state.error = Object.assign({}, state.error, error)
    },
    [DirectoryMutations.SET_ANALYZER_LIST](state, analyzerDirList) {
      state.analyzerList = Object.assign({}, state.analyzerList, analyzerDirList)
    },
    [DirectoryMutations.SET_TRANSFORMER_LIST](state, transformerDirList) {
      state.transformerList = Object.assign({}, state.transformerList, transformerDirList)
    }
  } as DirectoryModuleMutations,

  actions: {
    async [DirectoryActions.FETCH_ANALYZER_DIR_LIST]({ commit }, args) {
      commit(DirectoryMutations.SET_LOADING, true)
      await this.$fetchGraphqlData(AnalyzersGQLPublicQuery, args)
        .then((response: GraphqlQueryResponse) => {
          commit(DirectoryMutations.SET_ANALYZER_LIST, response.data.analyzers)
          commit(DirectoryMutations.SET_LOADING, false)
        })
        .catch((e: GraphqlError) => {
          commit(DirectoryMutations.SET_ERROR, e)
          commit(DirectoryMutations.SET_LOADING, false)
        })
    },
    async [DirectoryActions.FETCH_ANALYZER_DETAILS](
      { commit }: DirectoryActionContext,
      { shortcode, first = 5 }
    ) {
      commit(DirectoryMutations.SET_LOADING, true)
      try {
        const analyzerResponse = (await this.$fetchGraphqlData(AnalyzerDetailsQuery, {
          shortcode,
          first
        })) as GraphqlQueryResponse
        if (analyzerResponse?.data) {
          return analyzerResponse.data.analyzer as Analyzer
        }
      } catch (e) {
        commit(DirectoryMutations.SET_ERROR, e as GraphqlError)
      } finally {
        commit(DirectoryMutations.SET_LOADING, false)
      }
      return null
    },
    async [DirectoryActions.FETCH_ANALYZER_ISSUES]({ commit }, { first = 20, ...rest }) {
      commit(DirectoryMutations.SET_LOADING, true)
      try {
        const analyzerResponse = (await this.$fetchGraphqlData(AnalyzerIssuesQuery, {
          first,
          ...rest
        })) as GraphqlQueryResponse
        if (analyzerResponse?.data) {
          return analyzerResponse.data.analyzer as Analyzer
        }
      } catch (e) {
        commit(DirectoryMutations.SET_ERROR, e as GraphqlError)
      } finally {
        commit(DirectoryMutations.SET_LOADING, false)
      }
      return null
    },
    async [DirectoryActions.FETCH_TRANSFORMERS_DIR_LIST]({ commit }, args) {
      commit(DirectoryMutations.SET_LOADING, true)
      await this.$fetchGraphqlData(TransformersGQLPublicQuery, args)
        .then((response: GraphqlQueryResponse) => {
          commit(DirectoryMutations.SET_TRANSFORMER_LIST, response.data.transformers)
          commit(DirectoryMutations.SET_LOADING, false)
        })
        .catch((e: GraphqlError) => {
          commit(DirectoryMutations.SET_ERROR, e)
          commit(DirectoryMutations.SET_LOADING, false)
        })
    },
    async [DirectoryActions.ADD_ANALYZER_FEEDBACK]({ commit }, { input }) {
      try {
        const feedbackResponse = (await this.$applyGraphqlMutation(AddAnalyzerFeedbackMutation, {
          input
        })) as GraphqlMutationResponse
        if (feedbackResponse?.data.addAnalyzerFeedback?.ok) {
          this.$toast.success('Feedback successfully submitted.')
          return true
        }
      } catch (e) {
        commit(DirectoryMutations.SET_ERROR, e as GraphqlError)
        this.$logErrorAndToast(e as Error, 'There was an error submitting your feedback.')
      }
      return false
    },
    async [DirectoryActions.FETCH_TRANSFORMER_DETAILS]({ commit }, args) {
      commit(DirectoryMutations.SET_LOADING, true)
      try {
        const transformerResponse = (await this.$fetchGraphqlData(
          TransformersDetailsQuery,
          args
        )) as GraphqlQueryResponse
        if (transformerResponse?.data) {
          return transformerResponse.data.transformer as TransformerTool
        }
      } catch (e) {
        commit(DirectoryMutations.SET_ERROR, e as GraphqlError)
      } finally {
        commit(DirectoryMutations.SET_LOADING, false)
      }
      return null
    },
    async [DirectoryActions.ADD_TRANSFORMER_FEEDBACK]({ commit }, { input }) {
      try {
        const feedbackResponse = (await this.$applyGraphqlMutation(AddTransformerFeedbackMutation, {
          input
        })) as GraphqlMutationResponse
        if (feedbackResponse?.data.addTransformerFeedback?.ok) {
          this.$toast.success('Feedback successfully submitted.')
          return true
        }
      } catch (e) {
        commit(DirectoryMutations.SET_ERROR, e as GraphqlError)
        this.$logErrorAndToast(e as Error, 'There was an error submitting your feedback.')
      }
      return false
    }
  } as DirectoryModuleActions
}

export default directoryStoreModule
