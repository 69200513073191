import logoutMutation from '@/apollo/mutations/auth/logout.gql'
import refreshTokenMutation from '@/apollo/mutations/auth/refreshToken.gql'
import socialAuthMutation from '@/apollo/mutations/auth/socialAuth.gql'
import socialAuthUrlQuery from '@/apollo/queries/auth/socialAuthUrls.gql'
import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'
import { RootState } from '~/store'
import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { AuthUrl, SocialAuthUrl } from '~/types/types'
import { getJWTPayload } from '~/utils/jwt'

export enum AuthMutationTypes {
  SET_LOGGED_IN = 'setLoggedIn',
  SET_LOGGED_OUT = 'setLoggedOut',
  SET_AUTH_URLS = 'setAuthUrls',
  SET_ERROR = 'setError'
}

export enum AuthActionTypes {
  FETCH_AUTH_URLS = 'fetchAuthUrls',
  LOG_IN = 'login',
  REFRESH = 'refresh',
  LOG_OUT = 'logout',
  PURGE_CLIENT_DATA = 'purgeClientData'
}

export enum AuthGetterTypes {
  GET_LOGGED_IN = 'isLoggedIn',
  TOKEN = 'getJWT',
  EXPIRY = 'getJWTExpiry'
}

export interface AuthModuleState {
  token: string
  tokenExpiresIn: number
  loggedIn: boolean
  authUrls: Array<AuthUrl>
  error: Record<string, unknown>
}

export type AuthActionContext = ActionContext<AuthModuleState, RootState>

export interface AuthModuleGetters extends GetterTree<AuthModuleState, RootState> {
  [AuthGetterTypes.GET_LOGGED_IN](state: AuthModuleState): boolean
  [AuthGetterTypes.TOKEN](state: AuthModuleState): string
  [AuthGetterTypes.EXPIRY](state: AuthModuleState): number
}

export interface AuthModuleMutations extends MutationTree<AuthModuleState> {
  [AuthMutationTypes.SET_AUTH_URLS](state: AuthModuleState, oauth: SocialAuthUrl): void
  [AuthMutationTypes.SET_LOGGED_OUT](state: AuthModuleState): void
  [AuthMutationTypes.SET_LOGGED_IN](state: AuthModuleState, token: string): void
}

export interface AuthModuleActions extends ActionTree<AuthModuleState, RootState> {
  [AuthActionTypes.FETCH_AUTH_URLS](
    this: Store<RootState>,
    context: AuthActionContext
  ): Promise<void>
  [AuthActionTypes.LOG_IN](
    this: Store<RootState>,
    context: AuthActionContext,
    args: { code: string; provider: string; appId: string }
  ): Promise<void>
  [AuthActionTypes.REFRESH](this: Store<RootState>, context: AuthActionContext): Promise<void>
  [AuthActionTypes.PURGE_CLIENT_DATA](
    this: Store<RootState>,
    context: AuthActionContext,
    { onPrem }: { onPrem: boolean }
  ): Promise<void>
  [AuthActionTypes.LOG_OUT](
    this: Store<RootState>,
    context: AuthActionContext,
    { onPrem }: { onPrem: boolean }
  ): Promise<void>
}

const authModule: Module<AuthModuleState, RootState> = {
  state: (): AuthModuleState => ({
    token: '',
    tokenExpiresIn: 0,
    loggedIn: false,
    authUrls: [],
    error: {}
  }),

  getters: {
    [AuthGetterTypes.GET_LOGGED_IN]: (state) => {
      return state.loggedIn
    },
    [AuthGetterTypes.TOKEN]: (state) => {
      return state.token
    },
    [AuthGetterTypes.EXPIRY]: (state) => {
      return state.tokenExpiresIn
    }
  } as AuthModuleGetters,

  mutations: {
    [AuthMutationTypes.SET_AUTH_URLS]: (state, oauth) => {
      state.authUrls = oauth.socialUrls as Array<AuthUrl>
    },
    [AuthMutationTypes.SET_LOGGED_OUT]: (state) => {
      state.loggedIn = false
      state.token = ''
    },
    [AuthMutationTypes.SET_LOGGED_IN]: (state, token) => {
      try {
        if (token) {
          state.loggedIn = true
          state.token = token

          const { exp } = getJWTPayload(token)
          state.tokenExpiresIn = exp
        }
      } catch (e) {
        state.loggedIn = false
      }
    }
  } as AuthModuleMutations,

  actions: {
    async [AuthActionTypes.FETCH_AUTH_URLS]({ commit }) {
      const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
        socialAuthUrlQuery,
        {},
        false,
        false
      )
      commit(AuthMutationTypes.SET_AUTH_URLS, response.data.oauth)
    },

    async [AuthActionTypes.LOG_IN]({ commit }, args) {
      const response = await this.$applyGraphqlMutation(
        socialAuthMutation,
        {
          provider: args.provider,
          code: args.code,
          appId: args.appId
        },
        null,
        false
      )
      commit(AuthMutationTypes.SET_LOGGED_IN, response.data.socialAuth.token)
    },

    async [AuthActionTypes.REFRESH]({ commit }) {
      const response = await this.$applyGraphqlMutation(refreshTokenMutation, {}, null, false)
      if (response.data.refreshToken.token) {
        commit(AuthMutationTypes.SET_LOGGED_IN, response.data.refreshToken.token)
      } else {
        // To suppress JSONWebToken errors in Asgard, we implemented silent breakage on Asgard.
        // In case the refresh fails, Asgard will return a blank token.
        throw new Error('Failed to refresh token, please login again')
      }
    },

    async [AuthActionTypes.PURGE_CLIENT_DATA](_, { onPrem }) {
      try {
        // resets the indexedDB
        await this.$resetLocalDB()

        // reset rudder stack
        if (!onPrem && process.client) {
          this.$rudder?.reset(true)
          this.$sentry.setUser(null)
        }

        // reset the apollo cache
        this.$clearGqlStore()

        // purge localstorage except cookie consent
        this.$localStore.purge()

        // purge all client accessible cookies
        this.$cookies.removeAll()

        if (process.client && window && typeof indexedDB.databases === 'function') {
          const databases = await indexedDB.databases()

          Promise.all(
            databases
              .filter((db) => Boolean(db.name))
              .map((db) => {
                // @ts-expect-error - Already filtered for falsey name values
                return indexedDB.deleteDatabase(db.name)
              })
          ).catch((e) => {
            this.$logErrorAndToast(e as Error)
          })
        }
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Something went wrong while logging you out.')
        }
        this.$logErrorAndToast(e as Error)
      }
    },

    async [AuthActionTypes.LOG_OUT]({ commit, dispatch }, { onPrem }) {
      try {
        commit(AuthMutationTypes.SET_LOGGED_OUT)
        await this.$applyGraphqlMutation(logoutMutation, {}, null, false)
        dispatch(AuthActionTypes.PURGE_CLIENT_DATA, { onPrem }).catch((e) => {
          this.$logErrorAndToast(e as Error)
        })
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Something went wrong while logging you out.')
        }
        this.$logErrorAndToast(e as Error)
      }
    }
  } as AuthModuleActions
}

export default authModule
