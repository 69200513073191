import { ActionContext, ActionTree, Module, MutationTree, Store } from 'vuex'
import RepositoryTransformerGroupGQLQuery from '~/apollo/queries/repository/runs/transformerRun/group.gql'
import { RootState } from '~/store'
import { GraphqlError, GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { Maybe, PageInfo, TransformerRunConnection, TransformerRunEdge } from '~/types/types'

export enum TransformListActions {
  FETCH_TRANSFORMER_RUN_LIST = 'fetchTransformerRunList'
}

export enum TransformListMutations {
  SET_TRANSFORMER_RUN_LIST = 'setTransformerRunList',
  SET_LOADING = 'setTransformerRunListLoading',
  SET_ERROR = 'setTransformerRunListError'
}

export interface TransformerRunListModuleState {
  loading: boolean
  error: Record<string, any>
  transformerRunList: TransformerRunConnection
}

export type TransformerRunListActionContext = ActionContext<
  TransformerRunListModuleState,
  RootState
>

export interface TransformerRunListModuleMutations
  extends MutationTree<TransformerRunListModuleState> {
  [TransformListMutations.SET_LOADING](state: TransformerRunListModuleState, value: boolean): void
  [TransformListMutations.SET_ERROR](
    state: TransformerRunListModuleState,
    error: GraphqlError
  ): void
  [TransformListMutations.SET_TRANSFORMER_RUN_LIST](
    state: TransformerRunListModuleState,
    transformerRun: TransformerRunConnection
  ): void
}

export interface TransformerRunListModuleActions
  extends ActionTree<TransformerRunListModuleState, RootState> {
  [TransformListActions.FETCH_TRANSFORMER_RUN_LIST](
    this: Store<RootState>,
    { commit }: TransformerRunListActionContext,
    args: {
      provider: string
      owner: string
      name: string
      currentPageNumber: number
      limit: number
      refetch?: boolean
    }
  ): Promise<void>
}

const transformerRunListModule: Module<TransformerRunListModuleState, RootState> = {
  state: (): TransformerRunListModuleState => ({
    loading: false,
    error: {},
    transformerRunList: {
      pageInfo: {} as PageInfo,
      edges: [] as Array<Maybe<TransformerRunEdge>>
    }
  }),

  mutations: {
    [TransformListMutations.SET_LOADING](state, value) {
      state.loading = value
    },
    [TransformListMutations.SET_ERROR](state, error) {
      state.error = Object.assign({}, state.error, error)
    },
    [TransformListMutations.SET_TRANSFORMER_RUN_LIST](state, transformerRunList) {
      state.transformerRunList = Object.assign({}, state.transformerRunList, transformerRunList)
    }
  } as TransformerRunListModuleMutations,

  actions: {
    async [TransformListActions.FETCH_TRANSFORMER_RUN_LIST]({ commit }, args) {
      commit(TransformListMutations.SET_LOADING, true)
      await this.$fetchGraphqlData(
        RepositoryTransformerGroupGQLQuery,
        {
          provider: this.$providerMetaMap[args.provider].value,
          owner: args.owner,
          name: args.name,
          after: this.$getGQLAfter(args.currentPageNumber, args.limit),
          limit: args.limit
        },
        args.refetch
      )
        .then((response: GraphqlQueryResponse) => {
          commit(
            TransformListMutations.SET_TRANSFORMER_RUN_LIST,
            response.data.repository?.groupedTransformerRuns
          )
          commit(TransformListMutations.SET_LOADING, false)
        })
        .catch((e: GraphqlError) => {
          commit(TransformListMutations.SET_ERROR, e)
          commit(TransformListMutations.SET_LOADING, false)
        })
    }
  } as TransformerRunListModuleActions
}

export default transformerRunListModule
