import { ActionContext, ActionTree, Module, MutationTree, Store } from 'vuex'

import GetIntegrationInstallationUrlGQLMutation from '~/apollo/mutations/integrations/getIntegrationInstallationUrl.gql'
import InstallIntegrationGQLMutation from '~/apollo/mutations/integrations/installIntegration.gql'
import IntegrationDetailGQLQuery from '~/apollo/queries/integrations/integrationDetail.gql'
import IntegrationInstallationStatusGQLQuery from '~/apollo/queries/integrations/integrationInstallationStatus.gql'
import IntegrationLogoUrlGQLQuery from '~/apollo/queries/integrations/integrationLogoUrl.gql'

import CreateIssueGQLMutation from '~/apollo/mutations/integrations/createIssueOnIntergation.gql'
import UninstallIntegrationGQLMutation from '~/apollo/mutations/integrations/uninstallIntegration.gql'
import UpdateIntegrationSettingsGQLMutation from '~/apollo/mutations/integrations/updateIntegrationSettings.gql'

import { RootState } from '~/store'
import { GraphqlMutationResponse, GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import {
  CreateIssueOnIntegrationInput,
  CreateIssueOnIntegrationPayload,
  GetIntegrationInstallationUrlInput,
  GetIntegrationInstallationUrlPayload,
  InstallIntegrationInput,
  InstallIntegrationPayload,
  IntegrationProvider,
  IntegrationSettingsLevel,
  UninstallIntegrationInput,
  UpdateIntegrationSettingsInput,
  UpdateIntegrationSettingsPayload
} from '~/types/types'

export enum IntegrationsDetailActions {
  FETCH_INTEGRATION_DETAILS = 'fetchIntegrationDetails',
  FETCH_INTEGRATION_LOGO_URL = 'fetchIntegrationLogoUrl',
  FETCH_INTEGRATION_INSTALLATION_STATUS = 'fetchIntegrationInstallationStatus',
  GET_INTEGRATION_INSTALLATION_URL = 'getIntegrationInstallationUrl',
  INSTALL_INTEGRATION = 'installIntegration',
  UPDATE_INTEGRATION_SETTINGS = 'updateIntegrationSettings',
  UNINSTALL_INTEGRATION = 'uninstallIntegration',
  CREATE_ISSUE_ON_INTEGRATION = 'createIssueOnIntegration'
}

export enum IntegrationsDetailMutations {
  SET_INTEGRATION_DETAILS = 'setIntegrationDetails',
  SET_INSTALL_INTEGRATION_PAYLOAD = 'setInstallIntegrationPayload'
}

export interface IntegrationsDetailModuleState {
  integration: IntegrationProvider
  installIntegrationPayload: InstallIntegrationPayload
}

export type IntegrationsDetailActionContext = ActionContext<
  IntegrationsDetailModuleState,
  RootState
>

interface IntegrationsDetailModuleMutations extends MutationTree<IntegrationsDetailModuleState> {
  [IntegrationsDetailMutations.SET_INTEGRATION_DETAILS]: (
    state: IntegrationsDetailModuleState,
    integrations: IntegrationProvider
  ) => void

  [IntegrationsDetailMutations.SET_INSTALL_INTEGRATION_PAYLOAD]: (
    state: IntegrationsDetailModuleState,
    installIntegrationPayload: InstallIntegrationPayload
  ) => void
}

export interface IntegrationsDetailModuleActions
  extends ActionTree<IntegrationsDetailModuleState, RootState> {
  [IntegrationsDetailActions.FETCH_INTEGRATION_DETAILS]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: {
      shortcode: string
      level?: IntegrationSettingsLevel
      ownerId?: string
      repositoryId?: string
      refetch?: boolean
    }
  ) => Promise<void>

  [IntegrationsDetailActions.FETCH_INTEGRATION_LOGO_URL]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: {
      shortcode: string
      level?: IntegrationSettingsLevel
      ownerId?: string
      repositoryId?: string
      refetch?: boolean
    }
  ) => Promise<void>

  [IntegrationsDetailActions.FETCH_INTEGRATION_INSTALLATION_STATUS]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: {
      shortcode: string
      level?: IntegrationSettingsLevel
      ownerId?: string
      repositoryId?: string
      refetch?: boolean
    }
  ) => Promise<void>

  [IntegrationsDetailActions.GET_INTEGRATION_INSTALLATION_URL]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: GetIntegrationInstallationUrlInput
  ) => Promise<GetIntegrationInstallationUrlPayload>

  [IntegrationsDetailActions.INSTALL_INTEGRATION]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: InstallIntegrationInput
  ) => Promise<InstallIntegrationPayload>

  [IntegrationsDetailActions.UPDATE_INTEGRATION_SETTINGS]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: UpdateIntegrationSettingsInput
  ) => Promise<UpdateIntegrationSettingsPayload>

  [IntegrationsDetailActions.UNINSTALL_INTEGRATION]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: UninstallIntegrationInput
  ) => Promise<boolean>
  [IntegrationsDetailActions.CREATE_ISSUE_ON_INTEGRATION]: (
    this: Store<RootState>,
    { commit }: IntegrationsDetailActionContext,
    args: CreateIssueOnIntegrationInput
  ) => Promise<CreateIssueOnIntegrationPayload>
}

const integrationsDetailModule: Module<IntegrationsDetailModuleState, RootState> = {
  state: (): IntegrationsDetailModuleState => ({
    integration: {},
    installIntegrationPayload: {}
  }),

  mutations: {
    [IntegrationsDetailMutations.SET_INTEGRATION_DETAILS]: (state, integration) => {
      state.integration = Object.assign({}, state.integration, integration)
    },

    [IntegrationsDetailMutations.SET_INSTALL_INTEGRATION_PAYLOAD]: (
      state,
      installIntegrationPayload
    ) => {
      state.installIntegrationPayload = Object.assign(
        {},
        state.installIntegrationPayload,
        installIntegrationPayload
      )
    }
  } as IntegrationsDetailModuleMutations,

  actions: {
    async [IntegrationsDetailActions.FETCH_INTEGRATION_DETAILS]({ commit }, args) {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          IntegrationDetailGQLQuery,
          args,
          args.refetch
        )
        commit(IntegrationsDetailMutations.SET_INTEGRATION_DETAILS, response?.data?.integration)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching integration details.')
      }
    },

    async [IntegrationsDetailActions.FETCH_INTEGRATION_LOGO_URL]({ commit }, args) {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          IntegrationLogoUrlGQLQuery,
          args,
          args.refetch
        )
        commit(IntegrationsDetailMutations.SET_INTEGRATION_DETAILS, response?.data?.integration)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching integration logo URL.')
      }
    },

    async [IntegrationsDetailActions.FETCH_INTEGRATION_INSTALLATION_STATUS]({ commit }, args) {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          IntegrationInstallationStatusGQLQuery,
          args,
          args.refetch
        )
        commit(IntegrationsDetailMutations.SET_INTEGRATION_DETAILS, response?.data?.integration)
      } catch (e) {
        this.$logErrorAndToast(
          e as Error,
          'There was an error fetching integration installation status.'
        )
      }
    },

    async [IntegrationsDetailActions.GET_INTEGRATION_INSTALLATION_URL](_, args) {
      try {
        const response = await this.$applyGraphqlMutation(
          GetIntegrationInstallationUrlGQLMutation,
          {
            input: args
          }
        )
        return response?.data?.getIntegrationInstallationUrl
      } catch (e) {
        this.$logErrorAndToast(
          e as Error,
          'There was an error fetching integration installation URL.'
        )
      }
      return null
    },

    async [IntegrationsDetailActions.INSTALL_INTEGRATION]({ commit }, args) {
      try {
        const response = await this.$applyGraphqlMutation(InstallIntegrationGQLMutation, {
          input: args
        })
        commit(
          IntegrationsDetailMutations.SET_INSTALL_INTEGRATION_PAYLOAD,
          response?.data?.installIntegration
        )

        return response.data.installIntegration
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error installing the integration.')
      }
      return null
    },

    async [IntegrationsDetailActions.UPDATE_INTEGRATION_SETTINGS]({ dispatch }, args) {
      try {
        const response = await this.$applyGraphqlMutation(UpdateIntegrationSettingsGQLMutation, {
          input: args
        })

        const { shortcode, level, ownerId, repositoryId } = args
        await dispatch(IntegrationsDetailActions.FETCH_INTEGRATION_DETAILS, {
          shortcode,
          level,
          ownerId,
          repositoryId,
          refetch: true
        })

        return response?.data?.updateIntegrationSettings
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error updating the integration settings.')
      }
      return null
    },

    async [IntegrationsDetailActions.UNINSTALL_INTEGRATION]({ dispatch }, args) {
      const response: GraphqlMutationResponse = await this.$applyGraphqlMutation(
        UninstallIntegrationGQLMutation,
        {
          input: args
        }
      )
      const { shortcode, ownerId } = args
      await dispatch(IntegrationsDetailActions.FETCH_INTEGRATION_DETAILS, {
        shortcode,
        level: IntegrationSettingsLevel.Owner,
        ownerId,
        refetch: true
      })
      return Boolean(response?.data?.uninstallIntegration?.ok)
    },

    async [IntegrationsDetailActions.CREATE_ISSUE_ON_INTEGRATION](_, args) {
      const response = await this.$applyGraphqlMutation(CreateIssueGQLMutation, {
        input: args
      })
      return response?.data?.createIssueOnIntegration
    }
  } as IntegrationsDetailModuleActions
}

export default integrationsDetailModule
