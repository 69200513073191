import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'

import OrgLicenseQuery from '~/apollo/queries/control-panel/license/orgLicenseInfo.gql'

import { RootState } from '~/store'
import { GraphqlError, GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { License, TrendType } from '~/types/types'

export enum OrgLicenseActions {
  FETCH_ORG_LICENSE_DATA = 'fetchOrgLicenseData'
}

export enum OrgLicenseGetters {
  ORG_LICENSE_DATA = 'getOrgLicenseData'
}

export enum OrgLicenseMutations {
  SET_ORG_LICENSE_DATA = 'setOrgLicenseData',
  SET_ERROR = 'setError'
}

export interface OrgLicenseModuleState {
  OrgLicenseData: License
  isViewerSuperadmin: boolean
  error: GraphqlError | Record<string, unknown>
}

export type OrgLicenseActionContext = ActionContext<OrgLicenseModuleState, RootState>

export interface OrgLicenseModuleGetter extends GetterTree<OrgLicenseModuleState, RootState> {
  [OrgLicenseGetters.ORG_LICENSE_DATA](state: OrgLicenseModuleState): License
}

export interface OrgLicenseModuleMutations extends MutationTree<OrgLicenseModuleState> {
  [OrgLicenseMutations.SET_ORG_LICENSE_DATA](
    state: OrgLicenseModuleState,
    OrgLicenseData: License
  ): void
  [OrgLicenseMutations.SET_ERROR](state: OrgLicenseModuleState, error: GraphqlError): void
}

export interface OrgLicenseModuleActions extends ActionTree<OrgLicenseModuleState, RootState> {
  [OrgLicenseActions.FETCH_ORG_LICENSE_DATA](
    this: Store<RootState>,
    { commit }: OrgLicenseActionContext,
    args: { lastDays: number; trendType: TrendType }
  ): Promise<void>
}

const orgLicenseStoreModule: Module<OrgLicenseModuleState, RootState> = {
  state: (): OrgLicenseModuleState => ({
    OrgLicenseData: {} as License,
    isViewerSuperadmin: false,
    error: {}
  }),

  getters: {
    [OrgLicenseGetters.ORG_LICENSE_DATA]: (state) => {
      return state.OrgLicenseData as License
    }
  } as OrgLicenseModuleGetter,

  mutations: {
    [OrgLicenseMutations.SET_ORG_LICENSE_DATA]: (state, OrgLicenseData) => {
      state.OrgLicenseData = Object.assign({}, state.OrgLicenseData, OrgLicenseData)
    },
    [OrgLicenseMutations.SET_ERROR]: (state, error) => {
      state.error = Object.assign({}, state.error, error)
    }
  } as OrgLicenseModuleMutations,

  actions: {
    async [OrgLicenseActions.FETCH_ORG_LICENSE_DATA]({ commit }, args) {
      await this.$fetchGraphqlData(OrgLicenseQuery, args)
        .then((response: GraphqlQueryResponse) => {
          commit(OrgLicenseMutations.SET_ORG_LICENSE_DATA, response.data.enterprise?.license)
        })
        .catch((e: GraphqlError) => {
          commit(OrgLicenseMutations.SET_ERROR, e)
        })
    }
  } as OrgLicenseModuleActions
}

export default orgLicenseStoreModule
