import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'

import { RootState } from '~/store'
import { GraphqlError, GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import {
  AnalyzerConnection,
  PageInfo,
  RepositoryConnection,
  UpdateTechnologyPreferenceInput
} from '~/types/types'

// Queries
import PreferredTechnologiesGQLQuery from '~/apollo/queries/discover/preferredTechnologies.gql'
import WatchedRepositoriesGQLQuery from '~/apollo/queries/discover/watchedRepositories.gql'
import WatchedRepositoriesCountGQLQuery from '~/apollo/queries/discover/watchedRepositoriesCount.gql'

// Mutations
import UpdateTechnologyPreferenceGQLMutation from '~/apollo/mutations/discover/updateTechnologyPreference.gql'
import UpdateWatchedRepositoriesGQLMutation from '~/apollo/mutations/discover/updateWatchedRepositories.gql'

export enum DiscoverUserGetters {
  GET_PREFERRED_TECHNOLOGIES = 'getPreferredTechnologies',
  GET_WATCHED_REPOSITORIES = 'getWatchedRepositories'
}

export enum DiscoverUserActions {
  FETCH_PREFERRED_TECHNOLOGIES = 'fetchPreferredTechnologies',
  UPDATE_PREFERRED_TECHNOLOGIES = 'updatePreferredTechnologies',
  FETCH_WATCHED_REPOSITORIES = 'fetchWatchedRepositories',
  FETCH_WATCHED_REPOSITORIES_COUNT = 'fetchWatchedRepositoriesCount',
  UPDATE_WATCHED_REPOSITORIES = 'updateWatchedRepositories'
}

export enum DiscoverUserMutations {
  SET_ERROR = 'setError',
  SET_PREFERRED_TECHNOLOGIES = 'setPreferredTechnologies',
  SET_WATCHED_REPOSITORIES = 'setWatchedRepositories',
  SET_WATCHED_REPOSITORIES_COUNT = 'setWatchedRepositoriesCount'
}

export interface DiscoverUserState {
  error: GraphqlError
  preferredTechnologies: AnalyzerConnection
  watchedRepositoriesCount: number
  watchedRepositories: RepositoryConnection
}

export interface DiscoverUserModuleGetters extends GetterTree<DiscoverUserState, RootState> {
  [DiscoverUserGetters.GET_PREFERRED_TECHNOLOGIES](state: DiscoverUserState): AnalyzerConnection
  [DiscoverUserGetters.GET_WATCHED_REPOSITORIES](state: DiscoverUserState): RepositoryConnection
}

export interface DiscoverUserModuleMutations extends MutationTree<DiscoverUserState> {
  [DiscoverUserMutations.SET_ERROR](state: DiscoverUserState, error: GraphqlError): void
  [DiscoverUserMutations.SET_PREFERRED_TECHNOLOGIES](
    state: DiscoverUserState,
    preferredTechnologies: AnalyzerConnection
  ): void
  [DiscoverUserMutations.SET_WATCHED_REPOSITORIES](
    state: DiscoverUserState,
    watchedRepositories: RepositoryConnection
  ): void
  [DiscoverUserMutations.SET_WATCHED_REPOSITORIES_COUNT](
    state: DiscoverUserState,
    watchedRepositories: RepositoryConnection
  ): void
}

export type DiscoverUserActionContext = ActionContext<DiscoverUserState, RootState>

export interface DiscoverUserModuleActions extends ActionTree<DiscoverUserState, RootState> {
  [DiscoverUserActions.FETCH_PREFERRED_TECHNOLOGIES](
    this: Store<RootState>,
    { commit }: DiscoverUserActionContext,
    args: {
      refetch?: boolean
    }
  ): Promise<void>
  [DiscoverUserActions.UPDATE_PREFERRED_TECHNOLOGIES](
    this: Store<RootState>,
    { commit, dispatch }: DiscoverUserActionContext,
    args: {
      input: UpdateTechnologyPreferenceInput
    }
  ): Promise<void>
  [DiscoverUserActions.FETCH_WATCHED_REPOSITORIES](
    this: Store<RootState>,
    { commit }: DiscoverUserActionContext,
    args: {
      refetch?: boolean
    }
  ): Promise<void>
  [DiscoverUserActions.FETCH_WATCHED_REPOSITORIES_COUNT](
    this: Store<RootState>,
    { commit }: DiscoverUserActionContext
  ): Promise<void>
  [DiscoverUserActions.UPDATE_WATCHED_REPOSITORIES](
    this: Store<RootState>,
    { commit, dispatch }: DiscoverUserActionContext,
    args: {
      repoId: string
      action: 'ADD' | 'REMOVE'
    }
  ): Promise<void>
}

const discoverUserModule: Module<DiscoverUserState, RootState> = {
  state: (): DiscoverUserState => ({
    error: {} as GraphqlError,
    preferredTechnologies: {
      pageInfo: {} as PageInfo,
      totalCount: 0,
      edges: []
    } as AnalyzerConnection,
    watchedRepositoriesCount: 0,
    watchedRepositories: {
      pageInfo: {} as PageInfo,
      totalCount: 0,
      edges: []
    } as RepositoryConnection
  }),

  getters: {
    [DiscoverUserGetters.GET_PREFERRED_TECHNOLOGIES]: (state) => {
      return state.preferredTechnologies
    },

    [DiscoverUserGetters.GET_WATCHED_REPOSITORIES]: (state) => {
      return state.watchedRepositories
    }
  } as DiscoverUserModuleGetters,

  mutations: {
    [DiscoverUserMutations.SET_ERROR]: (state, error) => {
      state.error = Object.assign({}, state.error, error)
    },

    [DiscoverUserMutations.SET_PREFERRED_TECHNOLOGIES]: (state, preferredTechnologies) => {
      state.preferredTechnologies = Object.assign(
        {},
        state.preferredTechnologies,
        preferredTechnologies
      )
    },

    [DiscoverUserMutations.SET_WATCHED_REPOSITORIES]: (state, watchedRepositories) => {
      state.watchedRepositories = Object.assign({}, state.watchedRepositories, watchedRepositories)
    },
    [DiscoverUserMutations.SET_WATCHED_REPOSITORIES_COUNT]: (state, watchedRepositories) => {
      state.watchedRepositoriesCount = watchedRepositories.totalCount ?? 0
    }
  } as DiscoverUserModuleMutations,

  actions: {
    async [DiscoverUserActions.FETCH_PREFERRED_TECHNOLOGIES]({ commit }, args) {
      try {
        const refetch = args ? args.refetch : false
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          PreferredTechnologiesGQLQuery,
          args,
          refetch
        )

        const {
          data: { viewer }
        } = response
        commit(DiscoverUserMutations.SET_PREFERRED_TECHNOLOGIES, viewer?.preferredTechnologies)
      } catch (e) {
        const error = e as GraphqlError
        commit(DiscoverUserMutations.SET_ERROR, error)
      }
    },

    async [DiscoverUserActions.UPDATE_PREFERRED_TECHNOLOGIES]({ commit, dispatch }, { input }) {
      try {
        await this.$applyGraphqlMutation(UpdateTechnologyPreferenceGQLMutation, { input })
        await dispatch(DiscoverUserActions.FETCH_PREFERRED_TECHNOLOGIES, { refetch: true })
      } catch (e) {
        const error = e as GraphqlError
        commit(DiscoverUserMutations.SET_ERROR, error)
      }
    },

    async [DiscoverUserActions.FETCH_WATCHED_REPOSITORIES]({ commit }, args) {
      try {
        const refetch = args ? args.refetch : false
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          WatchedRepositoriesGQLQuery,
          args,
          refetch
        )

        const {
          data: { viewer }
        } = response
        commit(
          DiscoverUserMutations.SET_WATCHED_REPOSITORIES,
          viewer?.preference?.watchedRepositories
        )
      } catch (e) {
        const error = e as GraphqlError
        commit(DiscoverUserMutations.SET_ERROR, error)
      }
    },

    async [DiscoverUserActions.FETCH_WATCHED_REPOSITORIES_COUNT]({
      commit
    }: DiscoverUserActionContext): Promise<void> {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          WatchedRepositoriesCountGQLQuery,
          {},
          true
        )

        const {
          data: { viewer }
        } = response
        commit(
          DiscoverUserMutations.SET_WATCHED_REPOSITORIES_COUNT,
          viewer?.preference?.watchedRepositories
        )
      } catch (e) {
        const error = e as GraphqlError
        commit(DiscoverUserMutations.SET_ERROR, error)
      }
    },

    async [DiscoverUserActions.UPDATE_WATCHED_REPOSITORIES](
      { commit, dispatch },
      { repoId, action }
    ) {
      try {
        await this.$applyGraphqlMutation(UpdateWatchedRepositoriesGQLMutation, { repoId, action })
        dispatch(DiscoverUserActions.FETCH_WATCHED_REPOSITORIES, { refetch: true }).catch((e) => {
          this.$logErrorAndToast(e)
        })
        dispatch(DiscoverUserActions.FETCH_WATCHED_REPOSITORIES_COUNT).catch((e) => {
          this.$logErrorAndToast(e)
        })
      } catch (e) {
        const error = e as GraphqlError
        commit(DiscoverUserMutations.SET_ERROR, error)
      }
    }
  } as DiscoverUserModuleActions
}

export default discoverUserModule
