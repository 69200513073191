export const AnalyzerLogo = () => import('../../components/AnalyzerLogo.vue' /* webpackChunkName: "components/analyzer-logo" */).then(c => wrapFunctional(c.default || c))
export const CopyButton = () => import('../../components/CopyButton.vue' /* webpackChunkName: "components/copy-button" */).then(c => wrapFunctional(c.default || c))
export const DeepSourceLogo = () => import('../../components/DeepSourceLogo.vue' /* webpackChunkName: "components/deep-source-logo" */).then(c => wrapFunctional(c.default || c))
export const DeletedAccountState = () => import('../../components/DeletedAccountState.vue' /* webpackChunkName: "components/deleted-account-state" */).then(c => wrapFunctional(c.default || c))
export const IssueListItem = () => import('../../components/IssueListItem.vue' /* webpackChunkName: "components/issue-list-item" */).then(c => wrapFunctional(c.default || c))
export const LinkToPrev = () => import('../../components/LinkToPrev.vue' /* webpackChunkName: "components/link-to-prev" */).then(c => wrapFunctional(c.default || c))
export const NuxtLinkButton = () => import('../../components/NuxtLinkButton.vue' /* webpackChunkName: "components/nuxt-link-button" */).then(c => wrapFunctional(c.default || c))
export const UnstyledCopyButton = () => import('../../components/UnstyledCopyButton.vue' /* webpackChunkName: "components/unstyled-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CreateTokenModal = () => import('../../components/AccessToken/CreateTokenModal.vue' /* webpackChunkName: "components/create-token-modal" */).then(c => wrapFunctional(c.default || c))
export const TokenCard = () => import('../../components/AccessToken/TokenCard.vue' /* webpackChunkName: "components/token-card" */).then(c => wrapFunctional(c.default || c))
export const ActivateSingleRepo = () => import('../../components/AddRepo/ActivateSingleRepo.vue' /* webpackChunkName: "components/activate-single-repo" */).then(c => wrapFunctional(c.default || c))
export const AddRepoModal = () => import('../../components/AddRepo/AddRepoModal.vue' /* webpackChunkName: "components/add-repo-modal" */).then(c => wrapFunctional(c.default || c))
export const AutoOnboardRepos = () => import('../../components/AddRepo/AutoOnboardRepos.vue' /* webpackChunkName: "components/auto-onboard-repos" */).then(c => wrapFunctional(c.default || c))
export const RepoCard = () => import('../../components/AddRepo/RepoCard.vue' /* webpackChunkName: "components/repo-card" */).then(c => wrapFunctional(c.default || c))
export const SyncRepoAlert = () => import('../../components/AddRepo/SyncRepoAlert.vue' /* webpackChunkName: "components/sync-repo-alert" */).then(c => wrapFunctional(c.default || c))
export const AuditLogPage = () => import('../../components/AuditLog/AuditLogPage.vue' /* webpackChunkName: "components/audit-log-page" */).then(c => wrapFunctional(c.default || c))
export const DummyAuditLog = () => import('../../components/AuditLog/DummyAuditLog.vue' /* webpackChunkName: "components/dummy-audit-log" */).then(c => wrapFunctional(c.default || c))
export const ExportLogsSuccessModal = () => import('../../components/AuditLog/ExportLogsSuccessModal.vue' /* webpackChunkName: "components/export-logs-success-modal" */).then(c => wrapFunctional(c.default || c))
export const TimelineItemV2Loading = () => import('../../components/AuditLog/TimelineItemV2Loading.vue' /* webpackChunkName: "components/timeline-item-v2-loading" */).then(c => wrapFunctional(c.default || c))
export const ConfigTemplateCard = () => import('../../components/AutoOnboard/ConfigTemplateCard.vue' /* webpackChunkName: "components/config-template-card" */).then(c => wrapFunctional(c.default || c))
export const NewOnboardingTemplateModal = () => import('../../components/AutoOnboard/NewOnboardingTemplateModal.vue' /* webpackChunkName: "components/new-onboarding-template-modal" */).then(c => wrapFunctional(c.default || c))
export const AutofixCodeDiff = () => import('../../components/Autofix/AutofixCodeDiff.vue' /* webpackChunkName: "components/autofix-code-diff" */).then(c => wrapFunctional(c.default || c))
export const AutofixListItem = () => import('../../components/Autofix/AutofixListItem.vue' /* webpackChunkName: "components/autofix-list-item" */).then(c => wrapFunctional(c.default || c))
export const Info = () => import('../../components/Autofix/Info.vue' /* webpackChunkName: "components/info" */).then(c => wrapFunctional(c.default || c))
export const InstallAutofixNotice = () => import('../../components/Autofix/InstallAutofixNotice.vue' /* webpackChunkName: "components/install-autofix-notice" */).then(c => wrapFunctional(c.default || c))
export const BillingInfo = () => import('../../components/Billing/BillingInfo.vue' /* webpackChunkName: "components/billing-info" */).then(c => wrapFunctional(c.default || c))
export const CancelPlan = () => import('../../components/Billing/CancelPlan.vue' /* webpackChunkName: "components/cancel-plan" */).then(c => wrapFunctional(c.default || c))
export const DowngradePlan = () => import('../../components/Billing/DowngradePlan.vue' /* webpackChunkName: "components/downgrade-plan" */).then(c => wrapFunctional(c.default || c))
export const InvoiceList = () => import('../../components/Billing/InvoiceList.vue' /* webpackChunkName: "components/invoice-list" */).then(c => wrapFunctional(c.default || c))
export const PaymentInfo = () => import('../../components/Billing/PaymentInfo.vue' /* webpackChunkName: "components/payment-info" */).then(c => wrapFunctional(c.default || c))
export const PlanCard = () => import('../../components/Billing/PlanCard.vue' /* webpackChunkName: "components/plan-card" */).then(c => wrapFunctional(c.default || c))
export const PlanCards = () => import('../../components/Billing/PlanCards.vue' /* webpackChunkName: "components/plan-cards" */).then(c => wrapFunctional(c.default || c))
export const PlanInfo = () => import('../../components/Billing/PlanInfo.vue' /* webpackChunkName: "components/plan-info" */).then(c => wrapFunctional(c.default || c))
export const ResumePlan = () => import('../../components/Billing/ResumePlan.vue' /* webpackChunkName: "components/resume-plan" */).then(c => wrapFunctional(c.default || c))
export const StripeCardInput = () => import('../../components/Billing/StripeCardInput.vue' /* webpackChunkName: "components/stripe-card-input" */).then(c => wrapFunctional(c.default || c))
export const StripeCheckoutBox = () => import('../../components/Billing/StripeCheckoutBox.vue' /* webpackChunkName: "components/stripe-checkout-box" */).then(c => wrapFunctional(c.default || c))
export const StripeScaPendingAlert = () => import('../../components/Billing/StripeScaPendingAlert.vue' /* webpackChunkName: "components/stripe-sca-pending-alert" */).then(c => wrapFunctional(c.default || c))
export const UpgradePlan = () => import('../../components/Billing/UpgradePlan.vue' /* webpackChunkName: "components/upgrade-plan" */).then(c => wrapFunctional(c.default || c))
export const UsageDetails = () => import('../../components/Billing/UsageDetails.vue' /* webpackChunkName: "components/usage-details" */).then(c => wrapFunctional(c.default || c))
export const UsageInfo = () => import('../../components/Billing/UsageInfo.vue' /* webpackChunkName: "components/usage-info" */).then(c => wrapFunctional(c.default || c))
export const AlertBox = () => import('../../components/Common/AlertBox.vue' /* webpackChunkName: "components/alert-box" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbContainer = () => import('../../components/Common/BreadcrumbContainer.vue' /* webpackChunkName: "components/breadcrumb-container" */).then(c => wrapFunctional(c.default || c))
export const EmptyChart = () => import('../../components/Common/EmptyChart.vue' /* webpackChunkName: "components/empty-chart" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../components/Common/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const EmptyStateCard = () => import('../../components/Common/EmptyStateCard.vue' /* webpackChunkName: "components/empty-state-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyStatePicture = () => import('../../components/Common/EmptyStatePicture.vue' /* webpackChunkName: "components/empty-state-picture" */).then(c => wrapFunctional(c.default || c))
export const EnterpriseInstallationLogo = () => import('../../components/Common/EnterpriseInstallationLogo.vue' /* webpackChunkName: "components/enterprise-installation-logo" */).then(c => wrapFunctional(c.default || c))
export const FilterGeneric = () => import('../../components/Common/FilterGeneric.vue' /* webpackChunkName: "components/filter-generic" */).then(c => wrapFunctional(c.default || c))
export const FloatingButtonMobile = () => import('../../components/Common/FloatingButtonMobile.vue' /* webpackChunkName: "components/floating-button-mobile" */).then(c => wrapFunctional(c.default || c))
export const LogoutMenu = () => import('../../components/Common/LogoutMenu.vue' /* webpackChunkName: "components/logout-menu" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/Common/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const PaginationV2 = () => import('../../components/Common/PaginationV2.vue' /* webpackChunkName: "components/pagination-v2" */).then(c => wrapFunctional(c.default || c))
export const RunErrorBox = () => import('../../components/Common/RunErrorBox.vue' /* webpackChunkName: "components/run-error-box" */).then(c => wrapFunctional(c.default || c))
export const SidebarMenu = () => import('../../components/Common/SidebarMenu.vue' /* webpackChunkName: "components/sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const Ticker = () => import('../../components/Common/Ticker.vue' /* webpackChunkName: "components/ticker" */).then(c => wrapFunctional(c.default || c))
export const TimelineItemV2 = () => import('../../components/Common/TimelineItemV2.vue' /* webpackChunkName: "components/timeline-item-v2" */).then(c => wrapFunctional(c.default || c))
export const TimelineV2 = () => import('../../components/Common/TimelineV2.vue' /* webpackChunkName: "components/timeline-v2" */).then(c => wrapFunctional(c.default || c))
export const TomlBoxLite = () => import('../../components/Common/TomlBoxLite.vue' /* webpackChunkName: "components/toml-box-lite" */).then(c => wrapFunctional(c.default || c))
export const UpgradeFeature = () => import('../../components/Common/UpgradeFeature.vue' /* webpackChunkName: "components/upgrade-feature" */).then(c => wrapFunctional(c.default || c))
export const AddDefaultBranchModal = () => import('../../components/ConfigGenerator/AddDefaultBranchModal.vue' /* webpackChunkName: "components/add-default-branch-modal" */).then(c => wrapFunctional(c.default || c))
export const Analyzer = () => import('../../components/ConfigGenerator/Analyzer.vue' /* webpackChunkName: "components/analyzer" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerSearch = () => import('../../components/ConfigGenerator/AnalyzerSearch.vue' /* webpackChunkName: "components/analyzer-search" */).then(c => wrapFunctional(c.default || c))
export const ConfigAnalyzerSelector = () => import('../../components/ConfigGenerator/ConfigAnalyzerSelector.vue' /* webpackChunkName: "components/config-analyzer-selector" */).then(c => wrapFunctional(c.default || c))
export const NextStepsModal = () => import('../../components/ConfigGenerator/NextStepsModal.vue' /* webpackChunkName: "components/next-steps-modal" */).then(c => wrapFunctional(c.default || c))
export const PatternsSelector = () => import('../../components/ConfigGenerator/PatternsSelector.vue' /* webpackChunkName: "components/patterns-selector" */).then(c => wrapFunctional(c.default || c))
export const TomlBox = () => import('../../components/ConfigGenerator/TomlBox.vue' /* webpackChunkName: "components/toml-box" */).then(c => wrapFunctional(c.default || c))
export const PilotEvaluationAgreement = () => import('../../components/Content/PilotEvaluationAgreement.vue' /* webpackChunkName: "components/pilot-evaluation-agreement" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelCardsSkeleton = () => import('../../components/ControlPanel/ControlPanelCardsSkeleton.vue' /* webpackChunkName: "components/control-panel-cards-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelGroupHeader = () => import('../../components/ControlPanel/ControlPanelGroupHeader.vue' /* webpackChunkName: "components/control-panel-group-header" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelInvite = () => import('../../components/ControlPanel/ControlPanelInvite.vue' /* webpackChunkName: "components/control-panel-invite" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelUserCardInfo = () => import('../../components/ControlPanel/ControlPanelUserCardInfo.vue' /* webpackChunkName: "components/control-panel-user-card-info" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelUserHeader = () => import('../../components/ControlPanel/ControlPanelUserHeader.vue' /* webpackChunkName: "components/control-panel-user-header" */).then(c => wrapFunctional(c.default || c))
export const DeleteGroupButton = () => import('../../components/ControlPanel/DeleteGroupButton.vue' /* webpackChunkName: "components/delete-group-button" */).then(c => wrapFunctional(c.default || c))
export const DeleteUserButton = () => import('../../components/ControlPanel/DeleteUserButton.vue' /* webpackChunkName: "components/delete-user-button" */).then(c => wrapFunctional(c.default || c))
export const OktaIconWrapper = () => import('../../components/ControlPanel/OktaIconWrapper.vue' /* webpackChunkName: "components/okta-icon-wrapper" */).then(c => wrapFunctional(c.default || c))
export const RemoveTeamFromGroupButton = () => import('../../components/ControlPanel/RemoveTeamFromGroupButton.vue' /* webpackChunkName: "components/remove-team-from-group-button" */).then(c => wrapFunctional(c.default || c))
export const RemoveUserFromGroupButton = () => import('../../components/ControlPanel/RemoveUserFromGroupButton.vue' /* webpackChunkName: "components/remove-user-from-group-button" */).then(c => wrapFunctional(c.default || c))
export const RemoveUserFromTeamButton = () => import('../../components/ControlPanel/RemoveUserFromTeamButton.vue' /* webpackChunkName: "components/remove-user-from-team-button" */).then(c => wrapFunctional(c.default || c))
export const ToggleUserActiveButton = () => import('../../components/ControlPanel/ToggleUserActiveButton.vue' /* webpackChunkName: "components/toggle-user-active-button" */).then(c => wrapFunctional(c.default || c))
export const DirectoryCard = () => import('../../components/Directory/DirectoryCard.vue' /* webpackChunkName: "components/directory-card" */).then(c => wrapFunctional(c.default || c))
export const DirectoryHeader = () => import('../../components/Directory/DirectoryHeader.vue' /* webpackChunkName: "components/directory-header" */).then(c => wrapFunctional(c.default || c))
export const DirectoryTabs = () => import('../../components/Directory/DirectoryTabs.vue' /* webpackChunkName: "components/directory-tabs" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerFilterMobile = () => import('../../components/Discover/AnalyzerFilterMobile.vue' /* webpackChunkName: "components/analyzer-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const DiscoverRepoCard = () => import('../../components/Discover/DiscoverRepoCard.vue' /* webpackChunkName: "components/discover-repo-card" */).then(c => wrapFunctional(c.default || c))
export const EditorsPick = () => import('../../components/Discover/EditorsPick.vue' /* webpackChunkName: "components/editors-pick" */).then(c => wrapFunctional(c.default || c))
export const RepoFeed = () => import('../../components/Discover/RepoFeed.vue' /* webpackChunkName: "components/repo-feed" */).then(c => wrapFunctional(c.default || c))
export const SectionHeader = () => import('../../components/Discover/SectionHeader.vue' /* webpackChunkName: "components/section-header" */).then(c => wrapFunctional(c.default || c))
export const Trending = () => import('../../components/Discover/Trending.vue' /* webpackChunkName: "components/trending" */).then(c => wrapFunctional(c.default || c))
export const WatchedRepoFeed = () => import('../../components/Discover/WatchedRepoFeed.vue' /* webpackChunkName: "components/watched-repo-feed" */).then(c => wrapFunctional(c.default || c))
export const FiveHundred = () => import('../../components/Errors/FiveHundred.vue' /* webpackChunkName: "components/five-hundred" */).then(c => wrapFunctional(c.default || c))
export const FourOFour = () => import('../../components/Errors/FourOFour.vue' /* webpackChunkName: "components/four-o-four" */).then(c => wrapFunctional(c.default || c))
export const RequestAccess = () => import('../../components/Errors/RequestAccess.vue' /* webpackChunkName: "components/request-access" */).then(c => wrapFunctional(c.default || c))
export const ButtonInput = () => import('../../components/Form/ButtonInput.vue' /* webpackChunkName: "components/button-input" */).then(c => wrapFunctional(c.default || c))
export const CheckInput = () => import('../../components/Form/CheckInput.vue' /* webpackChunkName: "components/check-input" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/Form/FormField.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../components/Form/FormGroup.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const ImageInput = () => import('../../components/Form/ImageInput.vue' /* webpackChunkName: "components/image-input" */).then(c => wrapFunctional(c.default || c))
export const InputWrapper = () => import('../../components/Form/InputWrapper.vue' /* webpackChunkName: "components/input-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PasswordInput = () => import('../../components/Form/PasswordInput.vue' /* webpackChunkName: "components/password-input" */).then(c => wrapFunctional(c.default || c))
export const PasswordStrength = () => import('../../components/Form/PasswordStrength.vue' /* webpackChunkName: "components/password-strength" */).then(c => wrapFunctional(c.default || c))
export const RadioGroupInput = () => import('../../components/Form/RadioGroupInput.vue' /* webpackChunkName: "components/radio-group-input" */).then(c => wrapFunctional(c.default || c))
export const SelectInput = () => import('../../components/Form/SelectInput.vue' /* webpackChunkName: "components/select-input" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/Form/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const ToggleInput = () => import('../../components/Form/ToggleInput.vue' /* webpackChunkName: "components/toggle-input" */).then(c => wrapFunctional(c.default || c))
export const ToggleInputV2 = () => import('../../components/Form/ToggleInputV2.vue' /* webpackChunkName: "components/toggle-input-v2" */).then(c => wrapFunctional(c.default || c))
export const BaseGraph = () => import('../../components/Graphs/BaseGraph.vue' /* webpackChunkName: "components/base-graph" */).then(c => wrapFunctional(c.default || c))
export const GraphControl = () => import('../../components/Graphs/GraphControl.vue' /* webpackChunkName: "components/graph-control" */).then(c => wrapFunctional(c.default || c))
export const GraphLegend = () => import('../../components/Graphs/GraphLegend.vue' /* webpackChunkName: "components/graph-legend" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/History/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const BranchList = () => import('../../components/History/BranchList.vue' /* webpackChunkName: "components/branch-list" */).then(c => wrapFunctional(c.default || c))
export const IntegrationCallbackWrapper = () => import('../../components/Integrations/IntegrationCallbackWrapper.vue' /* webpackChunkName: "components/integration-callback-wrapper" */).then(c => wrapFunctional(c.default || c))
export const IntegrationCard = () => import('../../components/Integrations/IntegrationCard.vue' /* webpackChunkName: "components/integration-card" */).then(c => wrapFunctional(c.default || c))
export const IntegrationInfo = () => import('../../components/Integrations/IntegrationInfo.vue' /* webpackChunkName: "components/integration-info" */).then(c => wrapFunctional(c.default || c))
export const IntegrationInstalledBy = () => import('../../components/Integrations/IntegrationInstalledBy.vue' /* webpackChunkName: "components/integration-installed-by" */).then(c => wrapFunctional(c.default || c))
export const IntegrationInstalledOn = () => import('../../components/Integrations/IntegrationInstalledOn.vue' /* webpackChunkName: "components/integration-installed-on" */).then(c => wrapFunctional(c.default || c))
export const IntegrationTitle = () => import('../../components/Integrations/IntegrationTitle.vue' /* webpackChunkName: "components/integration-title" */).then(c => wrapFunctional(c.default || c))
export const LogoContainer = () => import('../../components/Integrations/LogoContainer.vue' /* webpackChunkName: "components/logo-container" */).then(c => wrapFunctional(c.default || c))
export const RepoErrorBanner = () => import('../../components/Integrations/RepoErrorBanner.vue' /* webpackChunkName: "components/repo-error-banner" */).then(c => wrapFunctional(c.default || c))
export const RepoIntegrationStatus = () => import('../../components/Integrations/RepoIntegrationStatus.vue' /* webpackChunkName: "components/repo-integration-status" */).then(c => wrapFunctional(c.default || c))
export const HeroCard = () => import('../../components/Invite/HeroCard.vue' /* webpackChunkName: "components/hero-card" */).then(c => wrapFunctional(c.default || c))
export const ActivateAnalysisModal = () => import('../../components/Issue/ActivateAnalysisModal.vue' /* webpackChunkName: "components/activate-analysis-modal" */).then(c => wrapFunctional(c.default || c))
export const ActivateRepoCta = () => import('../../components/Issue/ActivateRepoCta.vue' /* webpackChunkName: "components/activate-repo-cta" */).then(c => wrapFunctional(c.default || c))
export const AutofixAvailable = () => import('../../components/Issue/AutofixAvailable.vue' /* webpackChunkName: "components/autofix-available" */).then(c => wrapFunctional(c.default || c))
export const CategorySelector = () => import('../../components/Issue/CategorySelector.vue' /* webpackChunkName: "components/category-selector" */).then(c => wrapFunctional(c.default || c))
export const CategorySelectorMobile = () => import('../../components/Issue/CategorySelectorMobile.vue' /* webpackChunkName: "components/category-selector-mobile" */).then(c => wrapFunctional(c.default || c))
export const IssueAnalyzerSelector = () => import('../../components/Issue/IssueAnalyzerSelector.vue' /* webpackChunkName: "components/issue-analyzer-selector" */).then(c => wrapFunctional(c.default || c))
export const IssueCategoryFilter = () => import('../../components/Issue/IssueCategoryFilter.vue' /* webpackChunkName: "components/issue-category-filter" */).then(c => wrapFunctional(c.default || c))
export const IssueSearch = () => import('../../components/Issue/IssueSearch.vue' /* webpackChunkName: "components/issue-search" */).then(c => wrapFunctional(c.default || c))
export const IssueSort = () => import('../../components/Issue/IssueSort.vue' /* webpackChunkName: "components/issue-sort" */).then(c => wrapFunctional(c.default || c))
export const SeverityFilter = () => import('../../components/Issue/SeverityFilter.vue' /* webpackChunkName: "components/severity-filter" */).then(c => wrapFunctional(c.default || c))
export const ChooseIssueModal = () => import('../../components/IssuePriority/ChooseIssueModal.vue' /* webpackChunkName: "components/choose-issue-modal" */).then(c => wrapFunctional(c.default || c))
export const IssuePriorityCard = () => import('../../components/IssuePriority/IssuePriorityCard.vue' /* webpackChunkName: "components/issue-priority-card" */).then(c => wrapFunctional(c.default || c))
export const IssuePriorityFilter = () => import('../../components/IssuePriority/IssuePriorityFilter.vue' /* webpackChunkName: "components/issue-priority-filter" */).then(c => wrapFunctional(c.default || c))
export const IssuePriorityPage = () => import('../../components/IssuePriority/IssuePriorityPage.vue' /* webpackChunkName: "components/issue-priority-page" */).then(c => wrapFunctional(c.default || c))
export const IssuePrioritySort = () => import('../../components/IssuePriority/IssuePrioritySort.vue' /* webpackChunkName: "components/issue-priority-sort" */).then(c => wrapFunctional(c.default || c))
export const PriorityTypeBadge = () => import('../../components/IssuePriority/PriorityTypeBadge.vue' /* webpackChunkName: "components/priority-type-badge" */).then(c => wrapFunctional(c.default || c))
export const PriorityTypeSelect = () => import('../../components/IssuePriority/PriorityTypeSelect.vue' /* webpackChunkName: "components/priority-type-select" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeader = () => import('../../components/Layout/DashboardHeader.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const HeroLayout = () => import('../../components/Layout/HeroLayout.vue' /* webpackChunkName: "components/hero-layout" */).then(c => wrapFunctional(c.default || c))
export const LoggedOutSidebar = () => import('../../components/Layout/LoggedOutSidebar.vue' /* webpackChunkName: "components/logged-out-sidebar" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../components/Layout/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const RepoHeader = () => import('../../components/Layout/RepoHeader.vue' /* webpackChunkName: "components/repo-header" */).then(c => wrapFunctional(c.default || c))
export const UserHeader = () => import('../../components/Layout/UserHeader.vue' /* webpackChunkName: "components/user-header" */).then(c => wrapFunctional(c.default || c))
export const AdminNotices = () => import('../../components/Members/AdminNotices.vue' /* webpackChunkName: "components/admin-notices" */).then(c => wrapFunctional(c.default || c))
export const InviteMembersModal = () => import('../../components/Members/InviteMembersModal.vue' /* webpackChunkName: "components/invite-members-modal" */).then(c => wrapFunctional(c.default || c))
export const InviteMembersSuccessModal = () => import('../../components/Members/InviteMembersSuccessModal.vue' /* webpackChunkName: "components/invite-members-success-modal" */).then(c => wrapFunctional(c.default || c))
export const InvitedMemberListItem = () => import('../../components/Members/InvitedMemberListItem.vue' /* webpackChunkName: "components/invited-member-list-item" */).then(c => wrapFunctional(c.default || c))
export const MemberListItem = () => import('../../components/Members/MemberListItem.vue' /* webpackChunkName: "components/member-list-item" */).then(c => wrapFunctional(c.default || c))
export const MemberListItemLoading = () => import('../../components/Members/MemberListItemLoading.vue' /* webpackChunkName: "components/member-list-item-loading" */).then(c => wrapFunctional(c.default || c))
export const RemoveMemberModal = () => import('../../components/Members/RemoveMemberModal.vue' /* webpackChunkName: "components/remove-member-modal" */).then(c => wrapFunctional(c.default || c))
export const TransferOwnershipModal = () => import('../../components/Members/TransferOwnershipModal.vue' /* webpackChunkName: "components/transfer-ownership-modal" */).then(c => wrapFunctional(c.default || c))
export const UpdateRoleModal = () => import('../../components/Members/UpdateRoleModal.vue' /* webpackChunkName: "components/update-role-modal" */).then(c => wrapFunctional(c.default || c))
export const StatCard = () => import('../../components/Metrics/StatCard.vue' /* webpackChunkName: "components/stat-card" */).then(c => wrapFunctional(c.default || c))
export const StatSection = () => import('../../components/Metrics/StatSection.vue' /* webpackChunkName: "components/stat-section" */).then(c => wrapFunctional(c.default || c))
export const TrendCard = () => import('../../components/Metrics/TrendCard.vue' /* webpackChunkName: "components/trend-card" */).then(c => wrapFunctional(c.default || c))
export const AddSubRepositoryModal = () => import('../../components/Monorepo/AddSubRepositoryModal.vue' /* webpackChunkName: "components/add-sub-repository-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteSubRepository = () => import('../../components/Monorepo/DeleteSubRepository.vue' /* webpackChunkName: "components/delete-sub-repository" */).then(c => wrapFunctional(c.default || c))
export const DeleteSubRepositoryConfirm = () => import('../../components/Monorepo/DeleteSubRepositoryConfirm.vue' /* webpackChunkName: "components/delete-sub-repository-confirm" */).then(c => wrapFunctional(c.default || c))
export const MonorepoOverview = () => import('../../components/Monorepo/MonorepoOverview.vue' /* webpackChunkName: "components/monorepo-overview" */).then(c => wrapFunctional(c.default || c))
export const ToggleMonorepoConfirm = () => import('../../components/Monorepo/ToggleMonorepoConfirm.vue' /* webpackChunkName: "components/toggle-monorepo-confirm" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerCard = () => import('../../components/Onboard/AnalyzerCard.vue' /* webpackChunkName: "components/analyzer-card" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerPreview = () => import('../../components/Onboard/AnalyzerPreview.vue' /* webpackChunkName: "components/analyzer-preview" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerTitleCard = () => import('../../components/Onboard/AnalyzerTitleCard.vue' /* webpackChunkName: "components/analyzer-title-card" */).then(c => wrapFunctional(c.default || c))
export const RepoListItem = () => import('../../components/Onboard/RepoListItem.vue' /* webpackChunkName: "components/repo-list-item" */).then(c => wrapFunctional(c.default || c))
export const ActivityFeedList = () => import('../../components/PersonalDashboard/ActivityFeedList.vue' /* webpackChunkName: "components/activity-feed-list" */).then(c => wrapFunctional(c.default || c))
export const RecommendedIssues = () => import('../../components/PersonalDashboard/RecommendedIssues.vue' /* webpackChunkName: "components/recommended-issues" */).then(c => wrapFunctional(c.default || c))
export const StarredRepoList = () => import('../../components/PersonalDashboard/StarredRepoList.vue' /* webpackChunkName: "components/starred-repo-list" */).then(c => wrapFunctional(c.default || c))
export const Viewer = () => import('../../components/PersonalDashboard/Viewer.vue' /* webpackChunkName: "components/viewer" */).then(c => wrapFunctional(c.default || c))
export const IssueActions = () => import('../../components/RepoIssues/IssueActions.vue' /* webpackChunkName: "components/issue-actions" */).then(c => wrapFunctional(c.default || c))
export const IssueDescription = () => import('../../components/RepoIssues/IssueDescription.vue' /* webpackChunkName: "components/issue-description" */).then(c => wrapFunctional(c.default || c))
export const IssueDetailsHeader = () => import('../../components/RepoIssues/IssueDetailsHeader.vue' /* webpackChunkName: "components/issue-details-header" */).then(c => wrapFunctional(c.default || c))
export const IssueEditor = () => import('../../components/RepoIssues/IssueEditor.vue' /* webpackChunkName: "components/issue-editor" */).then(c => wrapFunctional(c.default || c))
export const IssueList = () => import('../../components/RepoIssues/IssueList.vue' /* webpackChunkName: "components/issue-list" */).then(c => wrapFunctional(c.default || c))
export const IssueOccurrenceSection = () => import('../../components/RepoIssues/IssueOccurrenceSection.vue' /* webpackChunkName: "components/issue-occurrence-section" */).then(c => wrapFunctional(c.default || c))
export const IssueOverviewCards = () => import('../../components/RepoOverview/IssueOverviewCards.vue' /* webpackChunkName: "components/issue-overview-cards" */).then(c => wrapFunctional(c.default || c))
export const BaseState = () => import('../../components/RepoStates/BaseState.vue' /* webpackChunkName: "components/base-state" */).then(c => wrapFunctional(c.default || c))
export const RepoCommunityWaiting = () => import('../../components/RepoStates/RepoCommunityWaiting.vue' /* webpackChunkName: "components/repo-community-waiting" */).then(c => wrapFunctional(c.default || c))
export const RepoEmpty = () => import('../../components/RepoStates/RepoEmpty.vue' /* webpackChunkName: "components/repo-empty" */).then(c => wrapFunctional(c.default || c))
export const RepoError = () => import('../../components/RepoStates/RepoError.vue' /* webpackChunkName: "components/repo-error" */).then(c => wrapFunctional(c.default || c))
export const RepoInactive = () => import('../../components/RepoStates/RepoInactive.vue' /* webpackChunkName: "components/repo-inactive" */).then(c => wrapFunctional(c.default || c))
export const RepoTimeout = () => import('../../components/RepoStates/RepoTimeout.vue' /* webpackChunkName: "components/repo-timeout" */).then(c => wrapFunctional(c.default || c))
export const RepoWaiting = () => import('../../components/RepoStates/RepoWaiting.vue' /* webpackChunkName: "components/repo-waiting" */).then(c => wrapFunctional(c.default || c))
export const ChartContainer = () => import('../../components/Reports/ChartContainer.vue' /* webpackChunkName: "components/chart-container" */).then(c => wrapFunctional(c.default || c))
export const ChartStat = () => import('../../components/Reports/ChartStat.vue' /* webpackChunkName: "components/chart-stat" */).then(c => wrapFunctional(c.default || c))
export const CodeCoverageSort = () => import('../../components/Reports/CodeCoverageSort.vue' /* webpackChunkName: "components/code-coverage-sort" */).then(c => wrapFunctional(c.default || c))
export const CodeCoverageTable = () => import('../../components/Reports/CodeCoverageTable.vue' /* webpackChunkName: "components/code-coverage-table" */).then(c => wrapFunctional(c.default || c))
export const CodeCoverageTableCell = () => import('../../components/Reports/CodeCoverageTableCell.vue' /* webpackChunkName: "components/code-coverage-table-cell" */).then(c => wrapFunctional(c.default || c))
export const CodeCoverageTableLoading = () => import('../../components/Reports/CodeCoverageTableLoading.vue' /* webpackChunkName: "components/code-coverage-table-loading" */).then(c => wrapFunctional(c.default || c))
export const ComplianceStatus = () => import('../../components/Reports/ComplianceStatus.vue' /* webpackChunkName: "components/compliance-status" */).then(c => wrapFunctional(c.default || c))
export const CreateReportSuccess = () => import('../../components/Reports/CreateReportSuccess.vue' /* webpackChunkName: "components/create-report-success" */).then(c => wrapFunctional(c.default || c))
export const DateRangePicker = () => import('../../components/Reports/DateRangePicker.vue' /* webpackChunkName: "components/date-range-picker" */).then(c => wrapFunctional(c.default || c))
export const DistributionStatCard = () => import('../../components/Reports/DistributionStatCard.vue' /* webpackChunkName: "components/distribution-stat-card" */).then(c => wrapFunctional(c.default || c))
export const DistributionStats = () => import('../../components/Reports/DistributionStats.vue' /* webpackChunkName: "components/distribution-stats" */).then(c => wrapFunctional(c.default || c))
export const DistributionSwitch = () => import('../../components/Reports/DistributionSwitch.vue' /* webpackChunkName: "components/distribution-switch" */).then(c => wrapFunctional(c.default || c))
export const EmptyPinnedCodeCoverageTable = () => import('../../components/Reports/EmptyPinnedCodeCoverageTable.vue' /* webpackChunkName: "components/empty-pinned-code-coverage-table" */).then(c => wrapFunctional(c.default || c))
export const MutateOwnerReportModal = () => import('../../components/Reports/MutateOwnerReportModal.vue' /* webpackChunkName: "components/mutate-owner-report-modal" */).then(c => wrapFunctional(c.default || c))
export const MutateReportModal = () => import('../../components/Reports/MutateReportModal.vue' /* webpackChunkName: "components/mutate-report-modal" */).then(c => wrapFunctional(c.default || c))
export const OccurrenceTags = () => import('../../components/Reports/OccurrenceTags.vue' /* webpackChunkName: "components/occurrence-tags" */).then(c => wrapFunctional(c.default || c))
export const PinnableReportsList = () => import('../../components/Reports/PinnableReportsList.vue' /* webpackChunkName: "components/pinnable-reports-list" */).then(c => wrapFunctional(c.default || c))
export const PinnedChartReport = () => import('../../components/Reports/PinnedChartReport.vue' /* webpackChunkName: "components/pinned-chart-report" */).then(c => wrapFunctional(c.default || c))
export const PinnedCodeCoverageReport = () => import('../../components/Reports/PinnedCodeCoverageReport.vue' /* webpackChunkName: "components/pinned-code-coverage-report" */).then(c => wrapFunctional(c.default || c))
export const PublicReportCard = () => import('../../components/Reports/PublicReportCard.vue' /* webpackChunkName: "components/public-report-card" */).then(c => wrapFunctional(c.default || c))
export const PublicReportPageWrapper = () => import('../../components/Reports/PublicReportPageWrapper.vue' /* webpackChunkName: "components/public-report-page-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PublicReportRepoSection = () => import('../../components/Reports/PublicReportRepoSection.vue' /* webpackChunkName: "components/public-report-repo-section" */).then(c => wrapFunctional(c.default || c))
export const PublicReportSidebar = () => import('../../components/Reports/PublicReportSidebar.vue' /* webpackChunkName: "components/public-report-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PublicReportSort = () => import('../../components/Reports/PublicReportSort.vue' /* webpackChunkName: "components/public-report-sort" */).then(c => wrapFunctional(c.default || c))
export const RecentStats = () => import('../../components/Reports/RecentStats.vue' /* webpackChunkName: "components/recent-stats" */).then(c => wrapFunctional(c.default || c))
export const ReportChartLegend = () => import('../../components/Reports/ReportChartLegend.vue' /* webpackChunkName: "components/report-chart-legend" */).then(c => wrapFunctional(c.default || c))
export const ReportDeleteConfirm = () => import('../../components/Reports/ReportDeleteConfirm.vue' /* webpackChunkName: "components/report-delete-confirm" */).then(c => wrapFunctional(c.default || c))
export const ReportRepositoriesPage = () => import('../../components/Reports/ReportRepositoriesPage.vue' /* webpackChunkName: "components/report-repositories-page" */).then(c => wrapFunctional(c.default || c))
export const ReportsSidebar = () => import('../../components/Reports/ReportsSidebar.vue' /* webpackChunkName: "components/reports-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SelectRepositoriesForReport = () => import('../../components/Reports/SelectRepositoriesForReport.vue' /* webpackChunkName: "components/select-repositories-for-report" */).then(c => wrapFunctional(c.default || c))
export const SeverityCounts = () => import('../../components/Reports/SeverityCounts.vue' /* webpackChunkName: "components/severity-counts" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerList = () => import('../../components/Repository/AnalyzerList.vue' /* webpackChunkName: "components/analyzer-list" */).then(c => wrapFunctional(c.default || c))
export const CoverageEnabledCard = () => import('../../components/Repository/CoverageEnabledCard.vue' /* webpackChunkName: "components/coverage-enabled-card" */).then(c => wrapFunctional(c.default || c))
export const EnableCoverageSteps = () => import('../../components/Repository/EnableCoverageSteps.vue' /* webpackChunkName: "components/enable-coverage-steps" */).then(c => wrapFunctional(c.default || c))
export const IgnoredRule = () => import('../../components/Repository/IgnoredRule.vue' /* webpackChunkName: "components/ignored-rule" */).then(c => wrapFunctional(c.default || c))
export const IssueSeverityTag = () => import('../../components/Repository/IssueSeverityTag.vue' /* webpackChunkName: "components/issue-severity-tag" */).then(c => wrapFunctional(c.default || c))
export const IssueType = () => import('../../components/Repository/IssueType.vue' /* webpackChunkName: "components/issue-type" */).then(c => wrapFunctional(c.default || c))
export const LastRunInfo = () => import('../../components/Repository/LastRunInfo.vue' /* webpackChunkName: "components/last-run-info" */).then(c => wrapFunctional(c.default || c))
export const MetadataView = () => import('../../components/Repository/MetadataView.vue' /* webpackChunkName: "components/metadata-view" */).then(c => wrapFunctional(c.default || c))
export const MetadataViewMobile = () => import('../../components/Repository/MetadataViewMobile.vue' /* webpackChunkName: "components/metadata-view-mobile" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerHeader = () => import('../../components/Run/AnalyzerHeader.vue' /* webpackChunkName: "components/analyzer-header" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerRun = () => import('../../components/Run/AnalyzerRun.vue' /* webpackChunkName: "components/analyzer-run" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerSelector = () => import('../../components/Run/AnalyzerSelector.vue' /* webpackChunkName: "components/analyzer-selector" */).then(c => wrapFunctional(c.default || c))
export const InferredArtifact = () => import('../../components/Run/InferredArtifact.vue' /* webpackChunkName: "components/inferred-artifact" */).then(c => wrapFunctional(c.default || c))
export const RunAutofixBar = () => import('../../components/Run/RunAutofixBar.vue' /* webpackChunkName: "components/run-autofix-bar" */).then(c => wrapFunctional(c.default || c))
export const RunCancelled = () => import('../../components/Run/RunCancelled.vue' /* webpackChunkName: "components/run-cancelled" */).then(c => wrapFunctional(c.default || c))
export const RunCommunityNuked = () => import('../../components/Run/RunCommunityNuked.vue' /* webpackChunkName: "components/run-community-nuked" */).then(c => wrapFunctional(c.default || c))
export const RunCommunityWaiting = () => import('../../components/Run/RunCommunityWaiting.vue' /* webpackChunkName: "components/run-community-waiting" */).then(c => wrapFunctional(c.default || c))
export const RunFailed = () => import('../../components/Run/RunFailed.vue' /* webpackChunkName: "components/run-failed" */).then(c => wrapFunctional(c.default || c))
export const RunHeader = () => import('../../components/Run/RunHeader.vue' /* webpackChunkName: "components/run-header" */).then(c => wrapFunctional(c.default || c))
export const RunLoading = () => import('../../components/Run/RunLoading.vue' /* webpackChunkName: "components/run-loading" */).then(c => wrapFunctional(c.default || c))
export const RunMetricCard = () => import('../../components/Run/RunMetricCard.vue' /* webpackChunkName: "components/run-metric-card" */).then(c => wrapFunctional(c.default || c))
export const RunMetricStat = () => import('../../components/Run/RunMetricStat.vue' /* webpackChunkName: "components/run-metric-stat" */).then(c => wrapFunctional(c.default || c))
export const RunNuked = () => import('../../components/Run/RunNuked.vue' /* webpackChunkName: "components/run-nuked" */).then(c => wrapFunctional(c.default || c))
export const RunPass = () => import('../../components/Run/RunPass.vue' /* webpackChunkName: "components/run-pass" */).then(c => wrapFunctional(c.default || c))
export const RunSelector = () => import('../../components/Run/RunSelector.vue' /* webpackChunkName: "components/run-selector" */).then(c => wrapFunctional(c.default || c))
export const RunSummary = () => import('../../components/Run/RunSummary.vue' /* webpackChunkName: "components/run-summary" */).then(c => wrapFunctional(c.default || c))
export const RunTimeout = () => import('../../components/Run/RunTimeout.vue' /* webpackChunkName: "components/run-timeout" */).then(c => wrapFunctional(c.default || c))
export const RunWaiting = () => import('../../components/Run/RunWaiting.vue' /* webpackChunkName: "components/run-waiting" */).then(c => wrapFunctional(c.default || c))
export const AccountCard = () => import('../../components/Settings/AccountCard.vue' /* webpackChunkName: "components/account-card" */).then(c => wrapFunctional(c.default || c))
export const DeleteUser = () => import('../../components/Settings/DeleteUser.vue' /* webpackChunkName: "components/delete-user" */).then(c => wrapFunctional(c.default || c))
export const InfoBanner = () => import('../../components/Settings/InfoBanner.vue' /* webpackChunkName: "components/info-banner" */).then(c => wrapFunctional(c.default || c))
export const Log = () => import('../../components/Settings/Log.vue' /* webpackChunkName: "components/log" */).then(c => wrapFunctional(c.default || c))
export const Notice = () => import('../../components/Settings/Notice.vue' /* webpackChunkName: "components/notice" */).then(c => wrapFunctional(c.default || c))
export const TeamLabel = () => import('../../components/Settings/TeamLabel.vue' /* webpackChunkName: "components/team-label" */).then(c => wrapFunctional(c.default || c))
export const UserProfile = () => import('../../components/Settings/UserProfile.vue' /* webpackChunkName: "components/user-profile" */).then(c => wrapFunctional(c.default || c))
export const BestEffortSupport = () => import('../../components/Support/BestEffortSupport.vue' /* webpackChunkName: "components/best-effort-support" */).then(c => wrapFunctional(c.default || c))
export const SupportDescription = () => import('../../components/Support/SupportDescription.vue' /* webpackChunkName: "components/support-description" */).then(c => wrapFunctional(c.default || c))
export const SupportFeatureInfo = () => import('../../components/Support/SupportFeatureInfo.vue' /* webpackChunkName: "components/support-feature-info" */).then(c => wrapFunctional(c.default || c))
export const SupportForm = () => import('../../components/Support/SupportForm.vue' /* webpackChunkName: "components/support-form" */).then(c => wrapFunctional(c.default || c))
export const SupportFormLabel = () => import('../../components/Support/SupportFormLabel.vue' /* webpackChunkName: "components/support-form-label" */).then(c => wrapFunctional(c.default || c))
export const SupportFpInfo = () => import('../../components/Support/SupportFpInfo.vue' /* webpackChunkName: "components/support-fp-info" */).then(c => wrapFunctional(c.default || c))
export const SupportIssueNotListed = () => import('../../components/Support/SupportIssueNotListed.vue' /* webpackChunkName: "components/support-issue-not-listed" */).then(c => wrapFunctional(c.default || c))
export const SupportSecurityWarning = () => import('../../components/Support/SupportSecurityWarning.vue' /* webpackChunkName: "components/support-security-warning" */).then(c => wrapFunctional(c.default || c))
export const AccountSetupCard = () => import('../../components/TeamHome/AccountSetupCard.vue' /* webpackChunkName: "components/account-setup-card" */).then(c => wrapFunctional(c.default || c))
export const ListItem = () => import('../../components/TeamHome/ListItem.vue' /* webpackChunkName: "components/list-item" */).then(c => wrapFunctional(c.default || c))
export const ListSection = () => import('../../components/TeamHome/ListSection.vue' /* webpackChunkName: "components/list-section" */).then(c => wrapFunctional(c.default || c))
export const RecentlyActiveRepoList = () => import('../../components/TeamHome/RecentlyActiveRepoList.vue' /* webpackChunkName: "components/recently-active-repo-list" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/Toast/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const CommandRow = () => import('../../components/ToitBar/CommandRow.vue' /* webpackChunkName: "components/command-row" */).then(c => wrapFunctional(c.default || c))
export const Palette = () => import('../../components/ToitBar/Palette.vue' /* webpackChunkName: "components/palette" */).then(c => wrapFunctional(c.default || c))
export const CreateWebhookModal = () => import('../../components/Webhooks/CreateWebhookModal.vue' /* webpackChunkName: "components/create-webhook-modal" */).then(c => wrapFunctional(c.default || c))
export const DeleteWebhookEndpoint = () => import('../../components/Webhooks/DeleteWebhookEndpoint.vue' /* webpackChunkName: "components/delete-webhook-endpoint" */).then(c => wrapFunctional(c.default || c))
export const EnableWebhookEndpoint = () => import('../../components/Webhooks/EnableWebhookEndpoint.vue' /* webpackChunkName: "components/enable-webhook-endpoint" */).then(c => wrapFunctional(c.default || c))
export const TestWebhookEndpoint = () => import('../../components/Webhooks/TestWebhookEndpoint.vue' /* webpackChunkName: "components/test-webhook-endpoint" */).then(c => wrapFunctional(c.default || c))
export const WebhookCard = () => import('../../components/Webhooks/WebhookCard.vue' /* webpackChunkName: "components/webhook-card" */).then(c => wrapFunctional(c.default || c))
export const WebhookLogList = () => import('../../components/Webhooks/WebhookLogList.vue' /* webpackChunkName: "components/webhook-log-list" */).then(c => wrapFunctional(c.default || c))
export const WebhookLogListItem = () => import('../../components/Webhooks/WebhookLogListItem.vue' /* webpackChunkName: "components/webhook-log-list-item" */).then(c => wrapFunctional(c.default || c))
export const ZAvatarV2 = () => import('../../components/zealv2/ZAvatarV2.vue' /* webpackChunkName: "components/z-avatar-v2" */).then(c => wrapFunctional(c.default || c))
export const ZSplitDropdownButtonV2 = () => import('../../components/zealv2/ZSplitDropdownButtonV2.vue' /* webpackChunkName: "components/z-split-dropdown-button-v2" */).then(c => wrapFunctional(c.default || c))
export const ZTagV2 = () => import('../../components/zealv2/ZTagV2.vue' /* webpackChunkName: "components/z-tag-v2" */).then(c => wrapFunctional(c.default || c))
export const AutoOnboardingMenu = () => import('../../components/AddRepo/AutoOnboard/AutoOnboardingMenu.vue' /* webpackChunkName: "components/auto-onboarding-menu" */).then(c => wrapFunctional(c.default || c))
export const InstallAutofixForAutoOnboard = () => import('../../components/AddRepo/AutoOnboard/InstallAutofixForAutoOnboard.vue' /* webpackChunkName: "components/install-autofix-for-auto-onboard" */).then(c => wrapFunctional(c.default || c))
export const SelectRepoToOnboard = () => import('../../components/AddRepo/AutoOnboard/SelectRepoToOnboard.vue' /* webpackChunkName: "components/select-repo-to-onboard" */).then(c => wrapFunctional(c.default || c))
export const SelectTemplate = () => import('../../components/AddRepo/AutoOnboard/SelectTemplate.vue' /* webpackChunkName: "components/select-template" */).then(c => wrapFunctional(c.default || c))
export const DeleteTemplateConfig = () => import('../../components/AutoOnboard/Actions/DeleteTemplateConfig.vue' /* webpackChunkName: "components/delete-template-config" */).then(c => wrapFunctional(c.default || c))
export const SaveTemplateConfig = () => import('../../components/AutoOnboard/Actions/SaveTemplateConfig.vue' /* webpackChunkName: "components/save-template-config" */).then(c => wrapFunctional(c.default || c))
export const InstallAutofixModal = () => import('../../components/Autofix/Modals/InstallAutofixModal.vue' /* webpackChunkName: "components/install-autofix-modal" */).then(c => wrapFunctional(c.default || c))
export const CancelPlanModal = () => import('../../components/Billing/Modals/CancelPlanModal.vue' /* webpackChunkName: "components/cancel-plan-modal" */).then(c => wrapFunctional(c.default || c))
export const UpdateBillingDetailsModal = () => import('../../components/Billing/Modals/UpdateBillingDetailsModal.vue' /* webpackChunkName: "components/update-billing-details-modal" */).then(c => wrapFunctional(c.default || c))
export const UpdateCardModal = () => import('../../components/Billing/Modals/UpdateCardModal.vue' /* webpackChunkName: "components/update-card-modal" */).then(c => wrapFunctional(c.default || c))
export const UpdateSubscriptionModal = () => import('../../components/Billing/Modals/UpdateSubscriptionModal.vue' /* webpackChunkName: "components/update-subscription-modal" */).then(c => wrapFunctional(c.default || c))
export const UpgradeAccountModal = () => import('../../components/Billing/Modals/UpgradeAccountModal.vue' /* webpackChunkName: "components/upgrade-account-modal" */).then(c => wrapFunctional(c.default || c))
export const UserBillingInfo = () => import('../../components/Billing/UserBilling/UserBillingInfo.vue' /* webpackChunkName: "components/user-billing-info" */).then(c => wrapFunctional(c.default || c))
export const UserPaymentCard = () => import('../../components/Billing/UserBilling/UserPaymentCard.vue' /* webpackChunkName: "components/user-payment-card" */).then(c => wrapFunctional(c.default || c))
export const UserPlanCard = () => import('../../components/Billing/UserBilling/UserPlanCard.vue' /* webpackChunkName: "components/user-plan-card" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelSidebar = () => import('../../components/ControlPanel/Layout/ControlPanelSidebar.vue' /* webpackChunkName: "components/control-panel-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelAddTeamToGroupModal = () => import('../../components/ControlPanel/Modals/ControlPanelAddTeamToGroupModal.vue' /* webpackChunkName: "components/control-panel-add-team-to-group-modal" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelEditGroupModal = () => import('../../components/ControlPanel/Modals/ControlPanelEditGroupModal.vue' /* webpackChunkName: "components/control-panel-edit-group-modal" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelFeedbackModal = () => import('../../components/ControlPanel/Modals/ControlPanelFeedbackModal.vue' /* webpackChunkName: "components/control-panel-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const ControlPanelGroupInviteModal = () => import('../../components/ControlPanel/Modals/ControlPanelGroupInviteModal.vue' /* webpackChunkName: "components/control-panel-group-invite-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateGroupModal = () => import('../../components/ControlPanel/Modals/CreateGroupModal.vue' /* webpackChunkName: "components/create-group-modal" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerDirStats = () => import('../../components/Directory/Analyzers/AnalyzerDirStats.vue' /* webpackChunkName: "components/analyzer-dir-stats" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerIssuesFilter = () => import('../../components/Directory/Analyzers/AnalyzerIssuesFilter.vue' /* webpackChunkName: "components/analyzer-issues-filter" */).then(c => wrapFunctional(c.default || c))
export const AnalyzerIssuesFilterMobile = () => import('../../components/Directory/Analyzers/AnalyzerIssuesFilterMobile.vue' /* webpackChunkName: "components/analyzer-issues-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const IssueDirCard = () => import('../../components/Directory/Analyzers/IssueDirCard.vue' /* webpackChunkName: "components/issue-dir-card" */).then(c => wrapFunctional(c.default || c))
export const DirectoryFeedbackModal = () => import('../../components/Directory/Modals/DirectoryFeedbackModal.vue' /* webpackChunkName: "components/directory-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const HeroHeader = () => import('../../components/Discover/Layout/HeroHeader.vue' /* webpackChunkName: "components/hero-header" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Discover/Layout/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const DirectoryCardSkeleton = () => import('../../components/Directory/Skeletons/DirectoryCardSkeleton.vue' /* webpackChunkName: "components/directory-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const IssueDirCardSkeleton = () => import('../../components/Directory/Skeletons/IssueDirCardSkeleton.vue' /* webpackChunkName: "components/issue-dir-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const AutofixFilters = () => import('../../components/History/Autofix/AutofixFilters.vue' /* webpackChunkName: "components/autofix-filters" */).then(c => wrapFunctional(c.default || c))
export const MetaDataItem = () => import('../../components/History/Runs/MetaDataItem.vue' /* webpackChunkName: "components/meta-data-item" */).then(c => wrapFunctional(c.default || c))
export const RunBranches = () => import('../../components/History/Runs/RunBranches.vue' /* webpackChunkName: "components/run-branches" */).then(c => wrapFunctional(c.default || c))
export const RunCard = () => import('../../components/History/Runs/RunCard.vue' /* webpackChunkName: "components/run-card" */).then(c => wrapFunctional(c.default || c))
export const RunFilters = () => import('../../components/History/Runs/RunFilters.vue' /* webpackChunkName: "components/run-filters" */).then(c => wrapFunctional(c.default || c))
export const TransformCard = () => import('../../components/History/Transforms/TransformCard.vue' /* webpackChunkName: "components/transform-card" */).then(c => wrapFunctional(c.default || c))
export const TabMenu = () => import('../../components/Layout/Navigation/TabMenu.vue' /* webpackChunkName: "components/tab-menu" */).then(c => wrapFunctional(c.default || c))
export const EventAlertsSection = () => import('../../components/Integrations/Slack/EventAlertsSection.vue' /* webpackChunkName: "components/event-alerts-section" */).then(c => wrapFunctional(c.default || c))
export const NotificationChannelSection = () => import('../../components/Integrations/Slack/NotificationChannelSection.vue' /* webpackChunkName: "components/notification-channel-section" */).then(c => wrapFunctional(c.default || c))
export const ContextSwitcher = () => import('../../components/Layout/Sidebar/ContextSwitcher.vue' /* webpackChunkName: "components/context-switcher" */).then(c => wrapFunctional(c.default || c))
export const ExtrasMenu = () => import('../../components/Layout/Sidebar/ExtrasMenu.vue' /* webpackChunkName: "components/extras-menu" */).then(c => wrapFunctional(c.default || c))
export const MainSidebar = () => import('../../components/Layout/Sidebar/MainSidebar.vue' /* webpackChunkName: "components/main-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PendingAdhocRepos = () => import('../../components/Layout/Sidebar/PendingAdhocRepos.vue' /* webpackChunkName: "components/pending-adhoc-repos" */).then(c => wrapFunctional(c.default || c))
export const RecentlyActiveRepo = () => import('../../components/Layout/Sidebar/RecentlyActiveRepo.vue' /* webpackChunkName: "components/recently-active-repo" */).then(c => wrapFunctional(c.default || c))
export const SidebarItem = () => import('../../components/Layout/Sidebar/SidebarItem.vue' /* webpackChunkName: "components/sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const SupportMenu = () => import('../../components/Layout/Sidebar/SupportMenu.vue' /* webpackChunkName: "components/support-menu" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/Layout/Sidebar/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const GraphChart = () => import('../../components/Metrics/PerformanceGraph/GraphChart.vue' /* webpackChunkName: "components/graph-chart" */).then(c => wrapFunctional(c.default || c))
export const GraphDataSelector = () => import('../../components/Metrics/PerformanceGraph/GraphDataSelector.vue' /* webpackChunkName: "components/graph-data-selector" */).then(c => wrapFunctional(c.default || c))
export const MetricsGraph = () => import('../../components/Metrics/PerformanceGraph/MetricsGraph.vue' /* webpackChunkName: "components/metrics-graph" */).then(c => wrapFunctional(c.default || c))
export const EditThresholdModal = () => import('../../components/Metrics/Trend/EditThresholdModal.vue' /* webpackChunkName: "components/edit-threshold-modal" */).then(c => wrapFunctional(c.default || c))
export const EmptyTrend = () => import('../../components/Metrics/Trend/EmptyTrend.vue' /* webpackChunkName: "components/empty-trend" */).then(c => wrapFunctional(c.default || c))
export const TrendSection = () => import('../../components/Metrics/Trend/TrendSection.vue' /* webpackChunkName: "components/trend-section" */).then(c => wrapFunctional(c.default || c))
export const TrendStat = () => import('../../components/Metrics/Trend/TrendStat.vue' /* webpackChunkName: "components/trend-stat" */).then(c => wrapFunctional(c.default || c))
export const TrendTitle = () => import('../../components/Metrics/Trend/TrendTitle.vue' /* webpackChunkName: "components/trend-title" */).then(c => wrapFunctional(c.default || c))
export const VerificationStep = () => import('../../components/Onboard/GSR/VerificationStep.vue' /* webpackChunkName: "components/verification-step" */).then(c => wrapFunctional(c.default || c))
export const AutofixFileChooser = () => import('../../components/RepoIssues/Modals/AutofixFileChooser.vue' /* webpackChunkName: "components/autofix-file-chooser" */).then(c => wrapFunctional(c.default || c))
export const AutofixIssuesChooser = () => import('../../components/RepoIssues/Modals/AutofixIssuesChooser.vue' /* webpackChunkName: "components/autofix-issues-chooser" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueAllFiles = () => import('../../components/RepoIssues/Modals/IgnoreIssueAllFiles.vue' /* webpackChunkName: "components/ignore-issue-all-files" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueFalsePositive = () => import('../../components/RepoIssues/Modals/IgnoreIssueFalsePositive.vue' /* webpackChunkName: "components/ignore-issue-false-positive" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueFilePattern = () => import('../../components/RepoIssues/Modals/IgnoreIssueFilePattern.vue' /* webpackChunkName: "components/ignore-issue-file-pattern" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueIntentional = () => import('../../components/RepoIssues/Modals/IgnoreIssueIntentional.vue' /* webpackChunkName: "components/ignore-issue-intentional" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueOccurrence = () => import('../../components/RepoIssues/Modals/IgnoreIssueOccurrence.vue' /* webpackChunkName: "components/ignore-issue-occurrence" */).then(c => wrapFunctional(c.default || c))
export const IgnoreIssueTestFiles = () => import('../../components/RepoIssues/Modals/IgnoreIssueTestFiles.vue' /* webpackChunkName: "components/ignore-issue-test-files" */).then(c => wrapFunctional(c.default || c))
export const CustomizeWidgetsModal = () => import('../../components/RepoOverview/CustomizeWidgets/CustomizeWidgetsModal.vue' /* webpackChunkName: "components/customize-widgets-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomizeWidgetsModalRow = () => import('../../components/RepoOverview/CustomizeWidgets/CustomizeWidgetsModalRow.vue' /* webpackChunkName: "components/customize-widgets-modal-row" */).then(c => wrapFunctional(c.default || c))
export const MonorepoSettings = () => import('../../components/Repository/Settings/MonorepoSettings.vue' /* webpackChunkName: "components/monorepo-settings" */).then(c => wrapFunctional(c.default || c))
export const RepoAnalysisSettings = () => import('../../components/Repository/Settings/RepoAnalysisSettings.vue' /* webpackChunkName: "components/repo-analysis-settings" */).then(c => wrapFunctional(c.default || c))
export const RepoAnalysisStatus = () => import('../../components/Repository/Settings/RepoAnalysisStatus.vue' /* webpackChunkName: "components/repo-analysis-status" */).then(c => wrapFunctional(c.default || c))
export const RepoAutofixSettings = () => import('../../components/Repository/Settings/RepoAutofixSettings.vue' /* webpackChunkName: "components/repo-autofix-settings" */).then(c => wrapFunctional(c.default || c))
export const RepoConfigDetails = () => import('../../components/Repository/Settings/RepoConfigDetails.vue' /* webpackChunkName: "components/repo-config-details" */).then(c => wrapFunctional(c.default || c))
export const RepoDiscoverSettings = () => import('../../components/Repository/Settings/RepoDiscoverSettings.vue' /* webpackChunkName: "components/repo-discover-settings" */).then(c => wrapFunctional(c.default || c))
export const RepoDsn = () => import('../../components/Repository/Settings/RepoDsn.vue' /* webpackChunkName: "components/repo-dsn" */).then(c => wrapFunctional(c.default || c))
export const RepoSshAccess = () => import('../../components/Repository/Settings/RepoSshAccess.vue' /* webpackChunkName: "components/repo-ssh-access" */).then(c => wrapFunctional(c.default || c))
export const AddCollaboratorModal = () => import('../../components/Settings/Modals/AddCollaboratorModal.vue' /* webpackChunkName: "components/add-collaborator-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmSyncModal = () => import('../../components/Settings/Modals/ConfirmSyncModal.vue' /* webpackChunkName: "components/confirm-sync-modal" */).then(c => wrapFunctional(c.default || c))
export const GenerateSshKeyConfirmModal = () => import('../../components/Settings/Modals/GenerateSshKeyConfirmModal.vue' /* webpackChunkName: "components/generate-ssh-key-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const IgnoredRuleDeleteModal = () => import('../../components/Settings/Modals/IgnoredRuleDeleteModal.vue' /* webpackChunkName: "components/ignored-rule-delete-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfigureSSO = () => import('../../components/Settings/Security/ConfigureSSO.vue' /* webpackChunkName: "components/configure-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDeleteIdP = () => import('../../components/Settings/Security/ConfirmDeleteIdP.vue' /* webpackChunkName: "components/confirm-delete-id-p" */).then(c => wrapFunctional(c.default || c))
export const DeleteIdp = () => import('../../components/Settings/Security/DeleteIdp.vue' /* webpackChunkName: "components/delete-idp" */).then(c => wrapFunctional(c.default || c))
export const ShowScimToken = () => import('../../components/Settings/Security/ShowScimToken.vue' /* webpackChunkName: "components/show-scim-token" */).then(c => wrapFunctional(c.default || c))
export const SsoConfiguration = () => import('../../components/Settings/Security/SsoConfiguration.vue' /* webpackChunkName: "components/sso-configuration" */).then(c => wrapFunctional(c.default || c))
export const UpgradeToEnterprise = () => import('../../components/Settings/Security/UpgradeToEnterprise.vue' /* webpackChunkName: "components/upgrade-to-enterprise" */).then(c => wrapFunctional(c.default || c))
export const DeleteTeamAccordion = () => import('../../components/TeamSettings/General/DeleteTeamAccordion.vue' /* webpackChunkName: "components/delete-team-accordion" */).then(c => wrapFunctional(c.default || c))
export const DeleteTeamConfirm = () => import('../../components/TeamSettings/General/DeleteTeamConfirm.vue' /* webpackChunkName: "components/delete-team-confirm" */).then(c => wrapFunctional(c.default || c))
export const TeamProfile = () => import('../../components/TeamSettings/General/TeamProfile.vue' /* webpackChunkName: "components/team-profile" */).then(c => wrapFunctional(c.default || c))
export const VcsConnections = () => import('../../components/TeamSettings/General/VcsConnections.vue' /* webpackChunkName: "components/vcs-connections" */).then(c => wrapFunctional(c.default || c))
export const ZAccordion = () => import('../../components/zeal/ZAccordion/ZAccordion.vue' /* webpackChunkName: "components/z-accordion" */).then(c => wrapFunctional(c.default || c))
export const ZAccordionItem = () => import('../../components/zeal/ZAccordionItem/ZAccordionItem.vue' /* webpackChunkName: "components/z-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const ZAlert = () => import('../../components/zeal/ZAlert/ZAlert.vue' /* webpackChunkName: "components/z-alert" */).then(c => wrapFunctional(c.default || c))
export const ZAvatar = () => import('../../components/zeal/ZAvatar/ZAvatar.vue' /* webpackChunkName: "components/z-avatar" */).then(c => wrapFunctional(c.default || c))
export const ZAvatarGroup = () => import('../../components/zeal/ZAvatarGroup/ZAvatarGroup.vue' /* webpackChunkName: "components/z-avatar-group" */).then(c => wrapFunctional(c.default || c))
export const ZBadge = () => import('../../components/zeal/ZBadge/ZBadge.vue' /* webpackChunkName: "components/z-badge" */).then(c => wrapFunctional(c.default || c))
export const ZBreadcrumb = () => import('../../components/zeal/ZBreadcrumb/ZBreadcrumb.vue' /* webpackChunkName: "components/z-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ZButton = () => import('../../components/zeal/ZButton/ZButton.vue' /* webpackChunkName: "components/z-button" */).then(c => wrapFunctional(c.default || c))
export const ZCard = () => import('../../components/zeal/ZCard/ZCard.vue' /* webpackChunkName: "components/z-card" */).then(c => wrapFunctional(c.default || c))
export const ZChart = () => import('../../components/zeal/ZChart/ZChart.vue' /* webpackChunkName: "components/z-chart" */).then(c => wrapFunctional(c.default || c))
export const ZCarousel = () => import('../../components/zeal/ZCarousel/ZCarousel.vue' /* webpackChunkName: "components/z-carousel" */).then(c => wrapFunctional(c.default || c))
export const ZCheckbox = () => import('../../components/zeal/ZCheckbox/ZCheckbox.vue' /* webpackChunkName: "components/z-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ZCode = () => import('../../components/zeal/ZCode/ZCode.vue' /* webpackChunkName: "components/z-code" */).then(c => wrapFunctional(c.default || c))
export const ZConfirm = () => import('../../components/zeal/ZConfirm/ZConfirm.vue' /* webpackChunkName: "components/z-confirm" */).then(c => wrapFunctional(c.default || c))
export const ZDialogGeneric = () => import('../../components/zeal/ZDialogGeneric/ZDialogGeneric.vue' /* webpackChunkName: "components/z-dialog-generic" */).then(c => wrapFunctional(c.default || c))
export const ZDivider = () => import('../../components/zeal/ZDivider/ZDivider.vue' /* webpackChunkName: "components/z-divider" */).then(c => wrapFunctional(c.default || c))
export const ZExpandable = () => import('../../components/zeal/ZExpandable/ZExpandable.vue' /* webpackChunkName: "components/z-expandable" */).then(c => wrapFunctional(c.default || c))
export const ZFileInput = () => import('../../components/zeal/ZFileInput/ZFileInput.vue' /* webpackChunkName: "components/z-file-input" */).then(c => wrapFunctional(c.default || c))
export const ZIcon = () => import('../../components/zeal/ZIcon/ZIcon.vue' /* webpackChunkName: "components/z-icon" */).then(c => wrapFunctional(c.default || c))
export const ZInput = () => import('../../components/zeal/ZInput/ZInput.vue' /* webpackChunkName: "components/z-input" */).then(c => wrapFunctional(c.default || c))
export const ZInputAddon = () => import('../../components/zeal/ZInputAddon/ZInputAddon.vue' /* webpackChunkName: "components/z-input-addon" */).then(c => wrapFunctional(c.default || c))
export const ZInputGroup = () => import('../../components/zeal/ZInputGroup/ZInputGroup.vue' /* webpackChunkName: "components/z-input-group" */).then(c => wrapFunctional(c.default || c))
export const ZLabel = () => import('../../components/zeal/ZLabel/ZLabel.vue' /* webpackChunkName: "components/z-label" */).then(c => wrapFunctional(c.default || c))
export const ZList = () => import('../../components/zeal/ZList/ZList.vue' /* webpackChunkName: "components/z-list" */).then(c => wrapFunctional(c.default || c))
export const ZListItem = () => import('../../components/zeal/ZListItem/ZListItem.vue' /* webpackChunkName: "components/z-list-item" */).then(c => wrapFunctional(c.default || c))
export const ZMenu = () => import('../../components/zeal/ZMenu/ZMenu.vue' /* webpackChunkName: "components/z-menu" */).then(c => wrapFunctional(c.default || c))
export const ZModal = () => import('../../components/zeal/ZModal/ZModal.vue' /* webpackChunkName: "components/z-modal" */).then(c => wrapFunctional(c.default || c))
export const ZNavBar = () => import('../../components/zeal/ZNavBar/ZNavBar.vue' /* webpackChunkName: "components/z-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const ZNumberInput = () => import('../../components/zeal/ZNumberInput/ZNumberInput.vue' /* webpackChunkName: "components/z-number-input" */).then(c => wrapFunctional(c.default || c))
export const ZOption = () => import('../../components/zeal/ZOption/ZOption.vue' /* webpackChunkName: "components/z-option" */).then(c => wrapFunctional(c.default || c))
export const ZPagination = () => import('../../components/zeal/ZPagination/ZPagination.vue' /* webpackChunkName: "components/z-pagination" */).then(c => wrapFunctional(c.default || c))
export const ZPulse = () => import('../../components/zeal/ZPulse/ZPulse.vue' /* webpackChunkName: "components/z-pulse" */).then(c => wrapFunctional(c.default || c))
export const ZRadio = () => import('../../components/zeal/ZRadio/ZRadio.vue' /* webpackChunkName: "components/z-radio" */).then(c => wrapFunctional(c.default || c))
export const ZRadioButton = () => import('../../components/zeal/ZRadioButton/ZRadioButton.vue' /* webpackChunkName: "components/z-radio-button" */).then(c => wrapFunctional(c.default || c))
export const ZRadioGroup = () => import('../../components/zeal/ZRadioGroup/ZRadioGroup.vue' /* webpackChunkName: "components/z-radio-group" */).then(c => wrapFunctional(c.default || c))
export const ZRichText = () => import('../../components/zeal/ZRichText/ZRichText.vue' /* webpackChunkName: "components/z-rich-text" */).then(c => wrapFunctional(c.default || c))
export const ZSelect = () => import('../../components/zeal/ZSelect/ZSelect.vue' /* webpackChunkName: "components/z-select" */).then(c => wrapFunctional(c.default || c))
export const ZSlide = () => import('../../components/zeal/ZSlide/ZSlide.vue' /* webpackChunkName: "components/z-slide" */).then(c => wrapFunctional(c.default || c))
export const ZSplitButtonDropdown = () => import('../../components/zeal/ZSplitButtonDropdown/ZSplitButtonDropdown.vue' /* webpackChunkName: "components/z-split-button-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ZStep = () => import('../../components/zeal/ZStep/ZStep.vue' /* webpackChunkName: "components/z-step" */).then(c => wrapFunctional(c.default || c))
export const ZStepper = () => import('../../components/zeal/ZStepper/ZStepper.vue' /* webpackChunkName: "components/z-stepper" */).then(c => wrapFunctional(c.default || c))
export const ZTab = () => import('../../components/zeal/ZTab/ZTab.vue' /* webpackChunkName: "components/z-tab" */).then(c => wrapFunctional(c.default || c))
export const ZTable = () => import('../../components/zeal/ZTable/ZTable.vue' /* webpackChunkName: "components/z-table" */).then(c => wrapFunctional(c.default || c))
export const ZTabs = () => import('../../components/zeal/ZTabs/ZTabs.vue' /* webpackChunkName: "components/z-tabs" */).then(c => wrapFunctional(c.default || c))
export const ZTag = () => import('../../components/zeal/ZTag/ZTag.vue' /* webpackChunkName: "components/z-tag" */).then(c => wrapFunctional(c.default || c))
export const ZTextarea = () => import('../../components/zeal/ZTextarea/ZTextarea.vue' /* webpackChunkName: "components/z-textarea" */).then(c => wrapFunctional(c.default || c))
export const ZTicker = () => import('../../components/zeal/ZTicker/ZTicker.vue' /* webpackChunkName: "components/z-ticker" */).then(c => wrapFunctional(c.default || c))
export const ZTimeline = () => import('../../components/zeal/ZTimeline/ZTimeline.vue' /* webpackChunkName: "components/z-timeline" */).then(c => wrapFunctional(c.default || c))
export const ZToggle = () => import('../../components/zeal/ZToggle/ZToggle.vue' /* webpackChunkName: "components/z-toggle" */).then(c => wrapFunctional(c.default || c))
export const ZMenuItem = () => import('../../components/zeal/ZMenu/ZMenuItem/ZMenuItem.vue' /* webpackChunkName: "components/z-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ZMenuSection = () => import('../../components/zeal/ZMenu/ZMenuSection/ZMenuSection.vue' /* webpackChunkName: "components/z-menu-section" */).then(c => wrapFunctional(c.default || c))
export const ZBreadcrumbItem = () => import('../../components/zeal/ZBreadcrumb/ZBreadcrumbItem/ZBreadcrumbItem.vue' /* webpackChunkName: "components/z-breadcrumb-item" */).then(c => wrapFunctional(c.default || c))
export const ZTableCell = () => import('../../components/zeal/ZTable/ZTableCell/ZTableCell.vue' /* webpackChunkName: "components/z-table-cell" */).then(c => wrapFunctional(c.default || c))
export const ZTableRow = () => import('../../components/zeal/ZTable/ZTableRow/ZTableRow.vue' /* webpackChunkName: "components/z-table-row" */).then(c => wrapFunctional(c.default || c))
export const ZTabItem = () => import('../../components/zeal/ZTabs/ZTabItem/ZTabItem.vue' /* webpackChunkName: "components/z-tab-item" */).then(c => wrapFunctional(c.default || c))
export const ZTabList = () => import('../../components/zeal/ZTabs/ZTabList/ZTabList.vue' /* webpackChunkName: "components/z-tab-list" */).then(c => wrapFunctional(c.default || c))
export const ZTabPane = () => import('../../components/zeal/ZTabs/ZTabPane/ZTabPane.vue' /* webpackChunkName: "components/z-tab-pane" */).then(c => wrapFunctional(c.default || c))
export const ZTabPanes = () => import('../../components/zeal/ZTabs/ZTabPanes/ZTabPanes.vue' /* webpackChunkName: "components/z-tab-panes" */).then(c => wrapFunctional(c.default || c))
export const ZTimelineItem = () => import('../../components/zeal/ZTimeline/ZTimelineItem/ZTimelineItem.vue' /* webpackChunkName: "components/z-timeline-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
