import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'

import BaseOrgDataQuery from '~/apollo/queries/control-panel/baseOrgInfo.gql'

import { RootState } from '~/store'
import { GraphqlError, GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { EnterpriseInstallationSetup } from '~/types/types'

export enum OrgBaseActions {
  FETCH_ORG_BASE_DATA = 'fetchOrgBaseData'
}

export enum OrgBaseGetters {
  ORG_BASE_DATA = 'getOrgBaseData',
  GET_MANAGEMENT_URL = 'getManagementUrl'
}

export enum OrgBaseMutations {
  SET_ORG_BASE_DATA = 'setOrgBaseData',
  SET_ERROR = 'setError',
  SET_MANAGEMENT_URL = 'setManagementUrl'
}

export interface OrgBaseModuleState {
  orgBaseData: EnterpriseInstallationSetup
  orgInstallationManagementUrl: string
  error: GraphqlError | Record<string, unknown>
}

export type OrgBaseActionContext = ActionContext<OrgBaseModuleState, RootState>

export interface OrgBaseModuleGetter extends GetterTree<OrgBaseModuleState, RootState> {
  [OrgBaseGetters.ORG_BASE_DATA](state: OrgBaseModuleState): EnterpriseInstallationSetup
  [OrgBaseGetters.GET_MANAGEMENT_URL](state: OrgBaseModuleState): string
}

export interface OrgBaseModuleMutations extends MutationTree<OrgBaseModuleState> {
  [OrgBaseMutations.SET_ORG_BASE_DATA](
    state: OrgBaseModuleState,
    orgData: EnterpriseInstallationSetup
  ): void
  [OrgBaseMutations.SET_MANAGEMENT_URL](
    state: OrgBaseModuleState,
    orgInstallationManagementUrl: string
  ): void
  [OrgBaseMutations.SET_ERROR](state: OrgBaseModuleState, error: GraphqlError): void
}

export interface OrgBaseModuleActions extends ActionTree<OrgBaseModuleState, RootState> {
  [OrgBaseActions.FETCH_ORG_BASE_DATA](
    this: Store<RootState>,
    { commit }: OrgBaseActionContext,
    args?: { q: string }
  ): Promise<void>
}

const orgBaseStoreModule: Module<OrgBaseModuleState, RootState> = {
  state: (): OrgBaseModuleState => ({
    ...(<OrgBaseModuleState>{
      orgBaseData: {} as EnterpriseInstallationSetup,
      orgInstallationManagementUrl: '',
      error: {}
    })
  }),

  getters: {
    [OrgBaseGetters.ORG_BASE_DATA]: (state) => {
      return state.orgBaseData as EnterpriseInstallationSetup
    },
    [OrgBaseGetters.GET_MANAGEMENT_URL]: (state) => {
      return state.orgInstallationManagementUrl as string
    }
  } as OrgBaseModuleGetter,

  mutations: {
    [OrgBaseMutations.SET_ORG_BASE_DATA]: (state, orgData) => {
      state.orgBaseData = Object.assign({}, state.orgBaseData, orgData)
    },
    [OrgBaseMutations.SET_MANAGEMENT_URL]: (state, orgInstallationManagementUrl) => {
      state.orgInstallationManagementUrl = orgInstallationManagementUrl ?? ''
    },
    [OrgBaseMutations.SET_ERROR]: (state, error) => {
      state.error = Object.assign({}, state.error, error)
    }
  } as OrgBaseModuleMutations,

  actions: {
    async [OrgBaseActions.FETCH_ORG_BASE_DATA]({ commit }, args) {
      await this.$fetchGraphqlData(BaseOrgDataQuery, args)
        .then((response: GraphqlQueryResponse) => {
          commit(OrgBaseMutations.SET_ORG_BASE_DATA, response.data.enterprise?.installation)
          commit(
            OrgBaseMutations.SET_MANAGEMENT_URL,
            response.data.enterprise?.managementConsoleUrl
          )
        })
        .catch((e: GraphqlError) => {
          commit(OrgBaseMutations.SET_ERROR, e)
        })
    }
  } as OrgBaseModuleActions
}

export default orgBaseStoreModule
