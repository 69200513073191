import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'

import ListIntegrationsGQLQuery from '~/apollo/queries/integrations/listIntegrations.gql'

import { RootState } from '~/store'
import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { IntegrationProvider, IntegrationSettingsLevel } from '~/types/types'

export enum IntegrationsListActions {
  FETCH_INTEGRATIONS = 'fetchIntegrations'
}

export enum IntegrationsListMutations {
  SET_INTEGRATIONS = 'setIntegrations'
}

export interface IntegrationsListModuleState {
  integrations: Array<IntegrationProvider>
}

export type IntegrationsListActionContext = ActionContext<IntegrationsListModuleState, RootState>

export interface IntegrationsListModuleMutations extends MutationTree<IntegrationsListModuleState> {
  [IntegrationsListMutations.SET_INTEGRATIONS]: (
    state: IntegrationsListModuleState,
    integrations: Array<IntegrationProvider>
  ) => void
}

export interface IntegrationsListModuleActions
  extends ActionTree<IntegrationsListModuleState, RootState> {
  [IntegrationsListActions.FETCH_INTEGRATIONS]: (
    this: Store<RootState>,
    injectee: IntegrationsListActionContext,
    args: {
      level?: IntegrationSettingsLevel
      ownerId?: string
      repositoryId?: string
      onlyInstalled?: boolean
      refetch?: boolean
    }
  ) => Promise<void>
}

const integrationsListStoreModule: Module<IntegrationsListModuleState, RootState> = {
  state: (): IntegrationsListModuleState => ({
    integrations: []
  }),
  mutations: {
    [IntegrationsListMutations.SET_INTEGRATIONS](state, integrations) {
      state.integrations = integrations
    }
  } as IntegrationsListModuleMutations,

  actions: {
    async [IntegrationsListActions.FETCH_INTEGRATIONS]({ commit }, args) {
      try {
        const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
          ListIntegrationsGQLQuery,
          args,
          args.refetch
        )
        commit(IntegrationsListMutations.SET_INTEGRATIONS, response?.data?.integrations)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching integrations.')
      }
    }
  } as IntegrationsListModuleActions
}

export default integrationsListStoreModule
