import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'

import { RootState } from '~/store'

import DeleteUser from '~/apollo/mutations/user/deleteUser.gql'
import UpdateDefaultContextMutation from '~/apollo/mutations/user/updateDefaultContext.gql'
import UpdateStarredRepos from '~/apollo/mutations/user/updateStarredRepo.gql'
import UpdateUserDetails from '~/apollo/mutations/user/updateUserDetails.gql'
import ActiveUserAccountInfo from '~/apollo/queries/user/active/accountInfo.gql'
import ActiveUserDetailGQLQuery from '~/apollo/queries/user/active/detail.gql'
import ActiveUserRecommendedIssues from '~/apollo/queries/user/active/recommendedIssues.gql'
import ActiveUserStarredRepos from '~/apollo/queries/user/active/starredRepos.gql'
import SuperadminCheckQuery from '~/apollo/queries/user/active/superadminCheck.gql'
import ActiveUserADSOrganizations from '~/apollo/queries/user/active/userADSOrganizations.gql'
import ActiveUserBBDProjects from '~/apollo/queries/user/active/userBBDProjects.gql'
import ActiveUserGitlabAccounts from '~/apollo/queries/user/active/userGitlabAccounts.gql'
import ActiveUserGSRProjects from '~/apollo/queries/user/active/userGSRProjects.gql'
import ActiveUserWorkspaces from '~/apollo/queries/user/active/workspaces.gql'

import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { DeleteRequestingUserInput, UpdateUserDetailsInput, User } from '~/types/types'

export interface ActiveUserState {
  viewer: User
  isViewerSuperadmin: boolean
}

export type ActiveUserActionContext = ActionContext<ActiveUserState, RootState>

export enum ActiveUserGetterTypes {
  GET_HOME_URL = 'getHomeUrl',
  GET_VIEWER = 'getViewer',
  IS_VIEWER_SUPERADMIN = 'getViewerSuperadminStatus'
}

export enum ActiveUserMutations {
  SET_VIEWER = 'setViewer',
  SET_VIEWER_SUPERADMIN = 'setViewerSuperadmin'
}

export enum ActiveUserActions {
  FETCH_VIEWER_INFO = 'fetchViewerInfo',
  FETCH_STARRED_REPOS = 'fetchStarredRepos',
  FETCH_GITLAB_ACCOUNTS = 'fetchGitlabAccounts',
  FETCH_GSR_PROJECTS = 'fetchGSRProjects',
  FETCH_ADS_ORGANIZATIONS = 'fetchAdsOrganizations',
  FETCH_BBD_PROJECTS = 'fetchBBDProjects',
  FETCH_RECOMMENDED_ISSUES = 'fetchRecommendedIssues',
  UPDATE_STARRED_REPO = 'updateStarredRepo',
  UPDATE_DEFAULT_CONTEXT = 'updateDefaultContext',
  FETCH_ACCOUNT_INFO = 'fetchAccountInfo',
  FETCH_WORKSPACES = 'fetchWorkspaces',
  UPDATE_USER_DETAILS = 'updateUserDetails',
  DELETE_USER = 'deleteUser',
  FETCH_VIEWER_SUPERADMIN = 'fetchViewerSuperadmin'
}

export interface ActiveUserModuleGetters extends GetterTree<ActiveUserState, RootState> {
  [ActiveUserGetterTypes.GET_HOME_URL](state: ActiveUserState): string
  [ActiveUserGetterTypes.GET_VIEWER](state: ActiveUserState): User
  [ActiveUserGetterTypes.IS_VIEWER_SUPERADMIN](state: ActiveUserState): boolean
}

export interface ActiveUserModuleMutations extends MutationTree<ActiveUserState> {
  [ActiveUserMutations.SET_VIEWER](state: ActiveUserState, viewer: User): void
  [ActiveUserMutations.SET_VIEWER_SUPERADMIN](
    state: ActiveUserState,
    isViewerSuperadmin: boolean
  ): void
}

export interface ActiveUserModuleActions extends ActionTree<ActiveUserState, RootState> {
  [ActiveUserActions.FETCH_VIEWER_INFO](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args: { refetch?: boolean }
  ): Promise<void>
  [ActiveUserActions.UPDATE_DEFAULT_CONTEXT](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args: { contextOwnerId: number }
  ): Promise<unknown>
  [ActiveUserActions.FETCH_STARRED_REPOS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args?: { refetch?: boolean }
  ): Promise<void>
  [ActiveUserActions.FETCH_GITLAB_ACCOUNTS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext
  ): Promise<void>
  [ActiveUserActions.FETCH_GSR_PROJECTS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext
  ): Promise<void>
  [ActiveUserActions.FETCH_BBD_PROJECTS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext
  ): Promise<void>
  [ActiveUserActions.FETCH_ADS_ORGANIZATIONS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args?: { refetch: boolean }
  ): Promise<void>
  [ActiveUserActions.UPDATE_STARRED_REPO](
    this: Store<RootState>,
    { dispatch }: ActiveUserActionContext,
    { repoId, action }: { repoId: string; action: 'ADD' | 'REMOVE' }
  ): Promise<unknown>
  [ActiveUserActions.FETCH_RECOMMENDED_ISSUES](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext
  ): Promise<void>
  [ActiveUserActions.FETCH_ACCOUNT_INFO](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args: { login: string; isViewerPrimaryUser: boolean; refetch?: boolean }
  ): Promise<void>
  [ActiveUserActions.FETCH_WORKSPACES](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    args: { login: string; refetch?: boolean }
  ): Promise<void>
  [ActiveUserActions.UPDATE_USER_DETAILS](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    { input }: { input: UpdateUserDetailsInput }
  ): Promise<User | undefined>
  [ActiveUserActions.DELETE_USER](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext,
    { input }: { input: DeleteRequestingUserInput }
  ): Promise<boolean>
  [ActiveUserActions.FETCH_VIEWER_SUPERADMIN](
    this: Store<RootState>,
    { commit }: ActiveUserActionContext
  ): Promise<void>
}

const activeUserModule: Module<ActiveUserState, RootState> = {
  state: (): ActiveUserState => ({
    ...(<ActiveUserState>{
      viewer: {},
      isViewerSuperadmin: false
    })
  }),

  getters: {
    [ActiveUserGetterTypes.GET_HOME_URL]: (state) => {
      // try returning the default context
      if (state.viewer.dashboardContext?.length) {
        const contextArray = state.viewer.dashboardContext.filter(
          (context: Record<string, string>) => {
            return context.is_default
          }
        )

        if (contextArray.length > 0) {
          return `/${contextArray[0].vcs_provider.toLowerCase()}/${contextArray[0].login}`
        }
      }

      // if not present, check for primary owner
      if (state.viewer.primaryOwner) {
        const vcsProvider: string = state.viewer.primaryOwner.vcsProvider.toLowerCase()
        return `/${vcsProvider}/${state.viewer.primaryOwner.login}`
      }

      // if there's no primary owner, send the first context
      if (state.viewer.dashboardContext?.length) {
        const ctx = state.viewer.dashboardContext[0]
        return `/${ctx.vcs_provider.toLowerCase()}/${ctx.login}`
      }

      // if all fails, that means there's no owner associated to the user,
      // send them to installation page
      return '/installation/providers'
    },
    [ActiveUserGetterTypes.GET_VIEWER]: (state) => {
      return state.viewer
    },
    [ActiveUserGetterTypes.IS_VIEWER_SUPERADMIN]: (state) => {
      return state.isViewerSuperadmin
    }
  } as ActiveUserModuleGetters,

  mutations: {
    [ActiveUserMutations.SET_VIEWER]: (state, viewer) => {
      state.viewer = Object.assign({}, state.viewer, viewer)
    },
    [ActiveUserMutations.SET_VIEWER_SUPERADMIN]: (state, isViewerSuperadmin) => {
      state.isViewerSuperadmin = isViewerSuperadmin ?? false
    }
  } as ActiveUserModuleMutations,

  actions: {
    async [ActiveUserActions.FETCH_VIEWER_INFO]({ commit }, args) {
      try {
        const refetch = args ? args.refetch : false
        const response = await this.$fetchGraphqlData(ActiveUserDetailGQLQuery, {}, refetch)
        commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'Unable to fetch user, please contact support.')
      }
    },
    async [ActiveUserActions.UPDATE_DEFAULT_CONTEXT](_, args) {
      try {
        const response = await this.$applyGraphqlMutation(UpdateDefaultContextMutation, {
          contextOwnerId: args.contextOwnerId
        })
        return response
      } catch (e) {
        this.$logErrorAndToast(
          e as Error,
          'Unable to update default organization, please contact support.'
        )
      }
      return undefined
    },
    async [ActiveUserActions.FETCH_STARRED_REPOS](
      { commit }: ActiveUserActionContext,
      args?: { refetch?: boolean }
    ) {
      const response = await this.$fetchGraphqlData(ActiveUserStarredRepos, {}, args?.refetch)
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_GITLAB_ACCOUNTS]({ commit }: ActiveUserActionContext) {
      const response = await this.$fetchGraphqlData(ActiveUserGitlabAccounts, {}, true)
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_GSR_PROJECTS]({ commit }: ActiveUserActionContext) {
      const response = await this.$fetchGraphqlData(ActiveUserGSRProjects, {}, true)
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_BBD_PROJECTS]({ commit }: ActiveUserActionContext) {
      const response = await this.$fetchGraphqlData(ActiveUserBBDProjects, {}, true)
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_ADS_ORGANIZATIONS]({ commit }, args = { refetch: true }) {
      const response = await this.$fetchGraphqlData(ActiveUserADSOrganizations, {}, args.refetch)
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.UPDATE_STARRED_REPO]({ dispatch }, { repoId, action }) {
      const res = await this.$applyGraphqlMutation(UpdateStarredRepos, { repoId, action })
      await dispatch(ActiveUserActions.FETCH_STARRED_REPOS, { refetch: true })
      return res.data.updateStarredRepository
    },
    async [ActiveUserActions.FETCH_RECOMMENDED_ISSUES]({ commit }: ActiveUserActionContext) {
      const response = await this.$fetchGraphqlData(ActiveUserRecommendedIssues, {})
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_ACCOUNT_INFO]({ commit }, args) {
      const { login, isViewerPrimaryUser, refetch } = args

      const response = await this.$fetchGraphqlData(
        ActiveUserAccountInfo,
        { login, isViewerPrimaryUser },
        Boolean(refetch)
      )
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.FETCH_WORKSPACES]({ commit }, args) {
      const { login, refetch } = args

      const response = await this.$fetchGraphqlData(
        ActiveUserWorkspaces,
        { login },
        Boolean(refetch)
      )
      commit(ActiveUserMutations.SET_VIEWER, response.data.viewer)
    },
    async [ActiveUserActions.UPDATE_USER_DETAILS]({ commit }, { input }) {
      try {
        const response = await this.$applyGraphqlMutation(UpdateUserDetails, { input })
        if (response?.data) {
          const { updateUserDetails } = response.data
          if (updateUserDetails.viewer && Object.keys(updateUserDetails.viewer).length) {
            commit(ActiveUserMutations.SET_VIEWER, updateUserDetails.viewer)
            return updateUserDetails.viewer
          }
        }
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'Unable to update user details, please contact support.')
      }
      return undefined
    },
    async [ActiveUserActions.DELETE_USER](_, { input }) {
      try {
        const response = await this.$applyGraphqlMutation(DeleteUser, { input })
        return Boolean(response?.data?.deleteRequestingUser?.ok)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'Unable to delete user, please contact support.')
      }
      return false
    },
    async [ActiveUserActions.FETCH_VIEWER_SUPERADMIN]({ commit }) {
      const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
        SuperadminCheckQuery,
        {},
        true
      )
      commit(ActiveUserMutations.SET_VIEWER_SUPERADMIN, response.data.isViewerSuperadmin)
    }
  } as ActiveUserModuleActions
}

export default activeUserModule
