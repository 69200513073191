
import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

/* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
const DISABLED_INTEGRATION_KEYS = []

export function getConfig (ctx) {
  const config = {
    dsn:"https:\u002F\u002Fec1d5fda51d8418a9e113ac85e01b54a@o193009.ingest.sentry.io\u002F4505248766558208",
    environment:"staging",
    tracesSampleRate:0.1,
    release:"7cf872b95e5a9edb170e5e873f430bffac5e8dce",
  }

  const resolvedIntegrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.1,"browserTracing":{"tracePropagationTargets":["localhost",new RegExp("^(https?:\\\u002F\\\u002F)?([^\\\u002F]+\\\u002F)*app\\.deepsource\\.one(\\:8000)?(\\\u002F[^\\s]*)?$", ""),new RegExp("^(https?:\\\u002F\\\u002F)?([^\\\u002F]+\\\u002F)*asgard-main\\.default(\\:8000)?(\\\u002F[^\\s]*)?$", "")]},"vueOptions":{"trackComponents":true,"hooks":["activate","create","mount","update","destroy"]},"vueRouterInstrumentationOptions":{"routeLabel":"name"}}
  resolvedIntegrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
