import { Component, mixins } from 'nuxt-property-decorator'
import { Maybe } from '~/types/types'
import ActiveUserMixin from './activeUserMixin'
import OwnerDetailMixin from './ownerDetailMixin'
import RepoDetailMixin from './repoDetailMixin'

let installWindow: Window | null = null

/**
 * Mixin class for installing Autofix app.
 */
@Component
export default class InstallAutofixMixin extends mixins(
  RepoDetailMixin,
  OwnerDetailMixin,
  ActiveUserMixin
) {
  public installing = false

  // Will trigger a fail 5 seconds after window closing if autofix is not enabled
  public failTimeout: ReturnType<typeof setTimeout>

  // Will check for window close every 1 second
  public popUpTimer: ReturnType<typeof setInterval>

  // Will poll asgard every 1.2 seconds to check if autofix is enabled
  // If enabled, we trigger the success action
  public pollTimer: ReturnType<typeof setInterval>

  // Close method exposed by the modal for a graceful close
  public close: Maybe<() => void> = null

  get isRepoLevel(): boolean {
    return Boolean(this.$route.params.repo)
  }

  get ownerParams(): { login: string; provider: string } {
    return { login: this.activeOwner, provider: this.activeProvider }
  }

  /**
   * Fetches the necessary details for the operation.
   * If the operation is at the repository level, it fetches the repository details.
   * Otherwise, it fetches the owner details.
   */
  async fetch(): Promise<void> {
    if (this.isRepoLevel) {
      await this.fetchRepoDetails(this.baseRouteParams)
    } else {
      await this.fetchOwnerDetails(this.ownerParams)
    }
  }

  /**
   * Fetches the basic repository details and refetches them if necessary.
   * @returns A Promise that resolves to void.
   */
  async refetchRepo(): Promise<void> {
    try {
      await this.fetchBasicRepoDetails({
        ...this.baseRouteParams,
        refetch: true
      })
    } catch (e) {
      this.$logErrorAndToast(e as Error, 'An error occured while fetching repository details.')
    }
  }

  /**
   * Fetches the owner details with an option to refetch.
   * @returns A promise that resolves when the owner details are fetched.
   */
  async refetchOwner(): Promise<void> {
    await this.fetchOwnerDetails({
      ...this.ownerParams,
      refetch: true
    })
  }

  /**
   * Clears all timers used in the mixin.
   */
  clearTimers(): void {
    clearTimeout(this.failTimeout)
    clearInterval(this.popUpTimer)
    clearInterval(this.pollTimer)
  }

  /**
   * Finish the installation process.
   *
   * @returns A promise that resolves when the installation is complete.
   */
  async finish(): Promise<void> {
    if (this.isRepoLevel) {
      await this.refetchRepo()
    }

    this.clearTimers()
    this.$socket.$off('autofix-installation-complete')

    if (this.close && typeof this.close === 'function') {
      this.close()
    } else {
      this.$emit('close')
    }
    this.installing = false
  }

  /**
   * Handles the success scenario after installing the Autofix app.
   * Clears any timers, closes the install window, displays a success toast message,
   * and finishes the installation process.
   * @returns A promise that resolves once the installation process is finished.
   */
  async success(): Promise<void> {
    this.clearTimers()
    installWindow?.postMessage('close', window.location.origin)
    this.$toast.success('Successfully installed Autofix app')
    await this.finish()
  }

  /**
   * Fail method for the InstallAutofixMixin class.
   * Clears timers, displays a danger toast message, and finishes the process.
   * @returns A Promise that resolves to void.
   */
  async fail(): Promise<void> {
    this.clearTimers()
    this.$toast.danger(
      'Autofix app was not installed. It could take a while to reflect these changes on DeepSource, check back in a few minutes '
    )
    await this.finish()
  }

  /**
   * Opens the autofix installation URL in a new window and performs necessary checks and actions.
   * @param close - Optional callback function to close the modal.
   * @returns A promise that resolves when the autofix installation is successful.
   */
  async openAutofixInstallationUrl({
    close,
    forceOwnerLevel
  }: {
    close?: () => void
    forceOwnerLevel?: boolean
  }): Promise<void> {
    // Assign the close received from the modal
    this.close = close ? close : null
    this.installing = true
    const isRepoLevel = forceOwnerLevel ? false : this.isRepoLevel

    let installationUrl = isRepoLevel
      ? this.repository.autofixInstallationUrl
      : this.owner.autofixInstallationUrl

    if (!installationUrl) {
      await (isRepoLevel ? this.refetchRepo() : this.refetchOwner())

      installationUrl = isRepoLevel
        ? this.repository.autofixInstallationUrl
        : this.owner.autofixInstallationUrl
    }

    if (installationUrl) {
      // open the window
      installWindow = window.open(installationUrl, '', 'resizable=no,width=1000,height=600')

      this.popUpTimer = setInterval(() => {
        if (installWindow?.closed) {
          this.failTimeout = setTimeout(() => {
            this.fail().catch((e) => {
              this.$logErrorAndToast(e)
            })
          }, 5000)
          clearInterval(this.popUpTimer)
          clearInterval(this.pollTimer)
        }
      }, 1000)

      if (isRepoLevel) {
        this.pollTimer = setInterval(() => {
          this.refetchRepo().catch((e) => {
            this.$logErrorAndToast(e)
          })
          if (this.repository.isAutofixEnabled) {
            clearInterval(this.pollTimer)
            this.success().catch((e) => {
              this.$logErrorAndToast(e)
            })
          }
        }, 1200)
      } else {
        this.pollTimer = setInterval(() => {
          this.refetchOwner().catch((e) => {
            this.$logErrorAndToast(e)
          })
          if (this.owner.isAutofixEnabled) {
            clearInterval(this.pollTimer)
            this.success().catch((e) => {
              this.$logErrorAndToast(e)
            })
          }
        }, 1200)
      }

      this.$socket.$on('autofix-installation-complete', async () => {
        await this.success()
      })
    }
  }

  /**
   * Lifecycle hook called before the component is destroyed.
   * Clears any timers and removes the event listener for 'autofix-installation-complete'.
   */
  beforeDestroy(): void {
    this.clearTimers()
    this.$socket.$off('autofix-installation-complete')
  }
}
