import { GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '~/store'

export interface IssuePreferencesInterface {
  issueTypes: Record<string, boolean>
}

export enum IssuePreferencesGetterTypes {
  ISSUE_TYPES = 'GET_ISSUE_TYPES'
}

export enum IssuePreferencesMutationTypes {
  UPDATE_ISSUE_TYPE = 'UPDATE_ISSUE_TYPE'
}

export interface IssuePreferencesModuleGetters
  extends GetterTree<IssuePreferencesInterface, RootState> {
  [IssuePreferencesGetterTypes.ISSUE_TYPES](
    state: IssuePreferencesInterface
  ): Record<string, boolean>
}

export interface IssuePreferencesModuleMutations extends MutationTree<IssuePreferencesInterface> {
  [IssuePreferencesMutationTypes.UPDATE_ISSUE_TYPE](
    state: IssuePreferencesInterface,
    issue: Record<string, boolean>
  ): void
}

const issuePreferencesStoreModule: Module<IssuePreferencesInterface, RootState> = {
  state: (): IssuePreferencesInterface => ({
    issueTypes: {}
  }),

  getters: {
    [IssuePreferencesGetterTypes.ISSUE_TYPES]: (state) => state.issueTypes || {}
  } as IssuePreferencesModuleGetters,

  mutations: {
    [IssuePreferencesMutationTypes.UPDATE_ISSUE_TYPE]: (state, issue) => {
      Object.assign(state.issueTypes, issue)
    }
  } as IssuePreferencesModuleMutations
}

export default issuePreferencesStoreModule
