import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/accessToken/accessToken.ts'), 'accessToken/accessToken.ts')
  resolveStoreModules(require('../store/account/auth.ts'), 'account/auth.ts')
  resolveStoreModules(require('../store/account/context.ts'), 'account/context.ts')
  resolveStoreModules(require('../store/analyzer/list.ts'), 'analyzer/list.ts')
  resolveStoreModules(require('../store/control-panel/base.ts'), 'control-panel/base.ts')
  resolveStoreModules(require('../store/control-panel/groups.ts'), 'control-panel/groups.ts')
  resolveStoreModules(require('../store/control-panel/license.ts'), 'control-panel/license.ts')
  resolveStoreModules(require('../store/control-panel/users.ts'), 'control-panel/users.ts')
  resolveStoreModules(require('../store/directory/directory.ts'), 'directory/directory.ts')
  resolveStoreModules(require('../store/discover/repositories.ts'), 'discover/repositories.ts')
  resolveStoreModules(require('../store/discover/user.ts'), 'discover/user.ts')
  resolveStoreModules(require('../store/integrations/detail.ts'), 'integrations/detail.ts')
  resolveStoreModules(require('../store/integrations/list.ts'), 'integrations/list.ts')
  resolveStoreModules(require('../store/issue/detail.ts'), 'issue/detail.ts')
  resolveStoreModules(require('../store/issuePriority/list.ts'), 'issuePriority/list.ts')
  resolveStoreModules(require('../store/owner/autoOnboard.ts'), 'owner/autoOnboard.ts')
  resolveStoreModules(require('../store/owner/detail.ts'), 'owner/detail.ts')
  resolveStoreModules(require('../store/owner/issuePreferences.ts'), 'owner/issuePreferences.ts')
  resolveStoreModules(require('../store/owner/subscription.ts'), 'owner/subscription.ts')
  resolveStoreModules(require('../store/owner/webhook.ts'), 'owner/webhook.ts')
  resolveStoreModules(require('../store/repository/detail.ts'), 'repository/detail.ts')
  resolveStoreModules(require('../store/repository/list.ts'), 'repository/list.ts')
  resolveStoreModules(require('../store/run/detail.ts'), 'run/detail.ts')
  resolveStoreModules(require('../store/run/list.ts'), 'run/list.ts')
  resolveStoreModules(require('../store/team/detail.ts'), 'team/detail.ts')
  resolveStoreModules(require('../store/transformerRun/list.ts'), 'transformerRun/list.ts')
  resolveStoreModules(require('../store/user/active.ts'), 'user/active.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
