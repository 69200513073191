import { ActionContext, ActionTree, GetterTree, Module, MutationTree, Store } from 'vuex'
import { RootState } from '~/store'
import { GraphqlQueryResponse } from '~/types/apollo-graphql-types'
import { Context } from '~/types/types'

import contextQuery from '~/apollo/queries/context/context.gql'
import toOnboardQuery from '~/apollo/queries/context/toOnboard.gql'

export enum ContextMutationTypes {
  SET_CONTEXT = 'setContext'
}

export enum ContextActionTypes {
  FETCH_CONTEXT = 'fetchContext',
  FETCH_TO_ONBOARD = 'fetchToOnboard'
}

export enum ContextGetterTypes {
  TO_ONBOARD = 'toOnboard',
  INSTALLATION_URL = 'installationUrl'
}

export interface ContextModuleState {
  context: Context
}

export interface ContextModuleGetters extends GetterTree<ContextModuleState, RootState> {
  [ContextGetterTypes.TO_ONBOARD](state: ContextModuleState): boolean
  [ContextGetterTypes.INSTALLATION_URL](
    state: ContextModuleState
  ): (provider: string) => string | undefined
}

export interface ContextModuleMutations extends MutationTree<ContextModuleState> {
  [ContextMutationTypes.SET_CONTEXT](state: ContextModuleState, context: Context): void
}

export type ContextActionContext = ActionContext<ContextModuleState, RootState>

export interface ContextModuleActions extends ActionTree<ContextModuleState, RootState> {
  [ContextActionTypes.FETCH_CONTEXT](
    this: Store<RootState>,
    { commit }: { commit: any },
    args?: { refetch?: boolean }
  ): Promise<void>
  [ContextActionTypes.FETCH_TO_ONBOARD](
    this: Store<RootState>,
    { commit, state }: { commit: any; state: ContextModuleState },
    args?: { refetch?: boolean }
  ): Promise<void>
}

const contextModule: Module<ContextModuleState, RootState> = {
  state: (): ContextModuleState => ({
    context: {}
  }),

  getters: {
    [ContextGetterTypes.TO_ONBOARD]: (state) => {
      return Boolean(state.context.toOnboard)
    },
    [ContextGetterTypes.INSTALLATION_URL]: (state) => (provider: string) => {
      return state.context.installationUrls[provider]
    }
  } as ContextModuleGetters,

  mutations: {
    [ContextMutationTypes.SET_CONTEXT]: (state, context) => {
      state.context = context
    }
  } as ContextModuleMutations,

  actions: {
    async [ContextActionTypes.FETCH_CONTEXT]({ commit }, args) {
      const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
        contextQuery,
        {},
        args?.refetch
      )
      commit(ContextMutationTypes.SET_CONTEXT, response.data.context)
    },
    async [ContextActionTypes.FETCH_TO_ONBOARD]({ commit, state }, args) {
      const response: GraphqlQueryResponse = await this.$fetchGraphqlData(
        toOnboardQuery,
        {},
        args?.refetch
      )

      const contextWithUpdatedToOnboard = {
        ...state.context,
        toOnboard: response.data.context?.toOnboard ?? state.context.toOnboard
      }
      commit(ContextMutationTypes.SET_CONTEXT, contextWithUpdatedToOnboard)
    }
  } as ContextModuleActions
}

export default contextModule
