import { ActionContext, ActionTree, Module, MutationTree, Store } from 'vuex'
import { RootState } from '~/store'
import {
  AutoOnboardEvent,
  AutoOnboardEventConnection,
  AutoOnboardPayload,
  ConfigTemplate,
  ConfigTemplateConnection,
  CreateConfigTemplatePayload,
  DeleteConfigTemplatePayload,
  Repository,
  RepositoryConnection,
  UpdateConfigTemplatePayload
} from '~/types/types'

// Queries
import getAutoOnboardableRepositoriesList from '~/apollo/queries/owner/auto-onboard/getAutoOnboardableRepositoriesList.gql'
import getAutoOnboardEventsList from '~/apollo/queries/owner/auto-onboard/getAutoOnboardEventsList.gql'
import getTemplateDetails from '~/apollo/queries/owner/auto-onboard/getTemplateDetails.gql'
import getTemplatesList from '~/apollo/queries/owner/auto-onboard/getTemplatesList.gql'

// Mutations
import autoOnboard from '~/apollo/mutations/owner/auto-onboard/autoOnboard.gql'
import createConfigTemplate from '~/apollo/mutations/owner/auto-onboard/createConfigTemplate.gql'
import deleteConfigTemplate from '~/apollo/mutations/owner/auto-onboard/deleteConfigTemplate.gql'
import updateConfigTemplate from '~/apollo/mutations/owner/auto-onboard/updateConfigTemplate.gql'
import { resolveNodes } from '~/utils/array'

export interface AutoOnboardState {
  configTemplate?: ConfigTemplate
  configTemplateList: ConfigTemplate[]
  totalTemplates: number
  onboardableRepositories: Repository[]
  autoOnboardEvents: AutoOnboardEvent[]
  repositoriesToOnboard: Repository['id'][]
  hasMoreReposToOnboard: boolean
  selectedTemplate?: ConfigTemplate
}

export enum AutoOnboardMutations {
  SET_ONBOARDABLE_REPOS = 'setOnboardAbleRepos',
  RESET_ONBOARDABLE_REPO_LIST = 'resetOnboardableReposList',
  SELECT_TEMPLATE_TO_ONBOARD = 'selectTemplateToOnboard',
  SET_CONFIG_TEMPLATE = 'setConfigTemplate',
  RESET_CONFIG_TEMPLATE = 'resetConfigTemplate',
  SET_CONFIG_TEMPLATE_LIST = 'setConfigTemplateList',
  SET_EVENT_LIST = 'setEventList'
}

export enum AutoOnboardActions {
  CREATE_TEMPLATE = 'createTemplate',
  DELETE_TEMPLATE = 'deleteTemplate',
  FETCH_SINGLE_TEMPLATE = 'fetchSingleTemplate',
  FETCH_TEMPLATES = 'fetchTemplates',
  FETCH_AUTO_ONBOARDABLE_REPO_LIST = 'fetchAutoOnboardableRepoList',
  START_ONBOARDING = 'startOnboarding',
  FETCH_ONBOARDING_EVENT_LIST = 'fetchOnboardingEventList',
  UPDATE_TEMPLATE = 'updateTemplate'
}

export interface AutoOnboardModuleMutations extends MutationTree<AutoOnboardState> {
  [AutoOnboardMutations.SET_ONBOARDABLE_REPOS](
    state: AutoOnboardState,
    value: RepositoryConnection
  ): void
  [AutoOnboardMutations.RESET_ONBOARDABLE_REPO_LIST](state: AutoOnboardState): void
  [AutoOnboardMutations.SELECT_TEMPLATE_TO_ONBOARD](
    state: AutoOnboardState,
    value: ConfigTemplate
  ): void
  [AutoOnboardMutations.SET_CONFIG_TEMPLATE](state: AutoOnboardState, value: ConfigTemplate): void
  [AutoOnboardMutations.RESET_CONFIG_TEMPLATE](state: AutoOnboardState): void
  [AutoOnboardMutations.SET_CONFIG_TEMPLATE_LIST](
    state: AutoOnboardState,
    value: ConfigTemplateConnection
  ): void
  [AutoOnboardMutations.SET_EVENT_LIST](
    state: AutoOnboardState,
    value: AutoOnboardEventConnection
  ): void
}

export type AutoOnboardActionsContext = ActionContext<AutoOnboardState, RootState>

export interface AutoOnboardModuleActions extends ActionTree<AutoOnboardState, RootState> {
  [AutoOnboardActions.CREATE_TEMPLATE](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: { ownerId: string; title: string; description: string; config: string }
  ): Promise<CreateConfigTemplatePayload>
  [AutoOnboardActions.DELETE_TEMPLATE](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: { shortcode: string }
  ): Promise<DeleteConfigTemplatePayload>
  [AutoOnboardActions.FETCH_SINGLE_TEMPLATE](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: { login: string; provider: string; shortcode: boolean; refetch?: boolean }
  ): Promise<void>
  [AutoOnboardActions.FETCH_TEMPLATES](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: {
      login: string
      provider: string
      currentPage: number
      limit: number
      q?: string
      refetch?: boolean
      commit?: boolean
    }
  ): Promise<ConfigTemplateConnection>
  [AutoOnboardActions.FETCH_AUTO_ONBOARDABLE_REPO_LIST](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: {
      login: string
      provider: string
      currentPageNumber: number
      limit: number
      query?: string
      refetch?: boolean
    }
  ): Promise<void>
  [AutoOnboardActions.START_ONBOARDING](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: {
      shortcode: string
      repoIds: string[]
    }
  ): Promise<AutoOnboardPayload>
  [AutoOnboardActions.FETCH_ONBOARDING_EVENT_LIST](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: { login: string; provider: string; refetch?: boolean }
  ): Promise<void>
  [AutoOnboardActions.UPDATE_TEMPLATE](
    this: Store<RootState>,
    { commit }: AutoOnboardActionsContext,
    args: { shortcode: string; title: string; description: string; config: string }
  ): Promise<UpdateConfigTemplatePayload>
}

const autoOnboardModule: Module<AutoOnboardState, RootState> = {
  state: (): AutoOnboardState => ({
    configTemplate: undefined,
    configTemplateList: [],
    totalTemplates: 0,
    onboardableRepositories: [],
    autoOnboardEvents: [],
    repositoriesToOnboard: [],
    selectedTemplate: undefined,
    hasMoreReposToOnboard: true
  }),

  mutations: {
    [AutoOnboardMutations.SET_ONBOARDABLE_REPOS](state, value) {
      state.hasMoreReposToOnboard = value.pageInfo.hasNextPage
      state.onboardableRepositories = state.onboardableRepositories.concat(resolveNodes(value))
    },
    [AutoOnboardMutations.RESET_ONBOARDABLE_REPO_LIST](state) {
      state.hasMoreReposToOnboard = true
      state.onboardableRepositories = []
    },
    [AutoOnboardMutations.SELECT_TEMPLATE_TO_ONBOARD](state, value) {
      state.selectedTemplate = value
    },
    [AutoOnboardMutations.SET_CONFIG_TEMPLATE](state, value) {
      state.configTemplate = value
    },
    [AutoOnboardMutations.RESET_CONFIG_TEMPLATE](state) {
      state.configTemplate = undefined
    },
    [AutoOnboardMutations.SET_CONFIG_TEMPLATE_LIST](state, value) {
      state.totalTemplates = value.totalCount ?? 0
      state.configTemplateList = resolveNodes(value)
    },
    [AutoOnboardMutations.SET_EVENT_LIST](state, value) {
      state.autoOnboardEvents = resolveNodes(value)
    }
  } as AutoOnboardModuleMutations,

  actions: {
    async [AutoOnboardActions.CREATE_TEMPLATE](_, args) {
      const response = await this.$applyGraphqlMutation(createConfigTemplate, args)
      return response.data.createConfigTemplate
    },
    async [AutoOnboardActions.DELETE_TEMPLATE](
      _,
      args: { shortcode: string }
    ): Promise<DeleteConfigTemplatePayload> {
      const response = await this.$applyGraphqlMutation(deleteConfigTemplate, args)
      return response.data.deleteConfigTemplate
    },
    async [AutoOnboardActions.FETCH_SINGLE_TEMPLATE]({ commit }, args) {
      try {
        const response = await this.$fetchGraphqlData(
          getTemplateDetails,
          {
            login: args.login,
            shortcode: args.shortcode,
            provider: this.$providerMetaMap[args.provider].value
          },
          args.refetch
        )
        commit(AutoOnboardMutations.SET_CONFIG_TEMPLATE, response.data.owner.configTemplate)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching.')
      }
    },
    async [AutoOnboardActions.FETCH_TEMPLATES]({ commit }, args) {
      try {
        const response = await this.$fetchGraphqlData(
          getTemplatesList,
          {
            login: args.login,
            after: this.$getGQLAfter(args.currentPage, args.limit),
            limit: args.limit,
            provider: this.$providerMetaMap[args.provider].value,
            q: args.q
          },
          args.refetch
        )
        if (args.commit) {
          commit(AutoOnboardMutations.SET_CONFIG_TEMPLATE_LIST, response.data.owner.configTemplates)
        }
        return response.data.owner.configTemplates
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching templates.')
        throw e
      }
    },
    async [AutoOnboardActions.FETCH_AUTO_ONBOARDABLE_REPO_LIST]({ commit }, args) {
      try {
        const response = await this.$fetchGraphqlData(
          getAutoOnboardableRepositoriesList,
          {
            login: args.login,
            provider: this.$providerMetaMap[args.provider].value,
            after: this.$getGQLAfter(args.currentPageNumber, args.limit),
            limit: args.limit,
            query: args.query
          },
          args.refetch
        )
        commit(AutoOnboardMutations.SET_ONBOARDABLE_REPOS, response.data.owner.repoList)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching.')
      }
    },
    async [AutoOnboardActions.START_ONBOARDING](_, args) {
      const response = await this.$applyGraphqlMutation(autoOnboard, args)
      return response.data.autoOnboard
    },
    async [AutoOnboardActions.FETCH_ONBOARDING_EVENT_LIST]({ commit }, args) {
      try {
        const response = await this.$fetchGraphqlData(
          getAutoOnboardEventsList,
          {
            login: args.login,
            provider: this.$providerMetaMap[args.provider].value
          },
          args.refetch
        )
        commit(AutoOnboardMutations.SET_EVENT_LIST, response.data.owner.autoonboardingEvents)
      } catch (e) {
        this.$logErrorAndToast(e as Error, 'There was an error fetching.')
      }
    },
    async [AutoOnboardActions.UPDATE_TEMPLATE](
      _,
      args: { shortcode: string; title: string; description: string; config: string }
    ): Promise<UpdateConfigTemplatePayload> {
      const response = await this.$applyGraphqlMutation(updateConfigTemplate, args)
      return response.data.updateConfigTemplate
    }
  } as AutoOnboardModuleActions
}

export default autoOnboardModule
