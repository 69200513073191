import { ActionContext, ActionTree, Module, MutationTree, Store } from 'vuex'

import RepositoryListGQLQuery from '~/apollo/queries/repository/list.gql'
import RepositoryAdHocPending from '~/apollo/queries/repository/listAdHocPending.gql'
import RecentlyActiveRepoList from '~/apollo/queries/repository/recentlyActiveRepoList.gql'

import { RootState } from '~/store'

import { GraphqlError } from '~/types/apollo-graphql-types'
import { Maybe, Owner, PageInfo, Repository, RepositoryConnection, Scalars } from '~/types/types'

import { resolveNodes } from '~/utils/array'

export interface RepoInterface {
  id: string
  language: string
  isPrivate: boolean
  name: string
  supportedAnalyzers: Array<string>
}

export enum RepoListActions {
  FETCH_REPOSITORY_LIST = 'fetchRepositoryList',
  FETCH_PENDING_ADHOC_REPOSITORY_LIST = 'fetchRepositoryListWithPendingAdhocRun',
  FETCH_NEW_REPOSITORY_LIST = 'fetchNewRepoList',
  FETCH_ACTIVE_REPOSITORY_LIST = 'fetchActiveAnalysisRepositoryList',
  FETCH_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS = 'fetchActiveAnalysisRepositoryListWithAnalyzers'
}

export enum RepoListMutations {
  SET_ERROR = 'setRepositoryListError',
  SET_LOADING = 'setRepositoryListLoading',
  SET_REPOSITORY_LIST = 'setRepositoryList',
  SET_NEW_REPOSITORY_LIST = 'setNewRepoList',
  SET_ACTIVE_REPOSITORY_LIST = 'setActiveAnalysisRepositoryList',
  SET_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS = 'setActiveAnalysisRepositoryListWithAnalyzers',
  SET_ADHOC_RUN_REPOS = 'setRepoWithPendingAdhocRuns'
}

export interface RepositoryListModuleState {
  loading: boolean
  error: Record<string, unknown>
  repositoryList: RepositoryConnection
  newRepos: RepositoryConnection
  repoWithActiveAnalysis: Repository[]
  repoWithActiveAnalysisWithAnalyzers: Repository[]
  repoWithPendingAdhocRuns: Repository[]
}

export type RepositoryListActionContext = ActionContext<RepositoryListModuleState, RootState>

export interface RepositoryListModuleMutations extends MutationTree<RepositoryListModuleState> {
  [RepoListMutations.SET_LOADING](state: RepositoryListModuleState, value: boolean): void
  [RepoListMutations.SET_ERROR](state: RepositoryListModuleState, error: GraphqlError): void
  [RepoListMutations.SET_REPOSITORY_LIST](
    state: RepositoryListModuleState,
    repositoryList: RepositoryConnection
  ): void
  [RepoListMutations.SET_NEW_REPOSITORY_LIST](
    state: RepositoryListModuleState,
    repositoryList: RepositoryConnection
  ): void
  [RepoListMutations.SET_ACTIVE_REPOSITORY_LIST](
    state: RepositoryListModuleState,
    repositoryList: RepositoryConnection
  ): void
  [RepoListMutations.SET_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS](
    state: RepositoryListModuleState,
    repositoryList: RepositoryConnection
  ): void
  [RepoListMutations.SET_ADHOC_RUN_REPOS](
    state: RepositoryListModuleState,
    repoConnect: RepositoryConnection
  ): void
}

export interface RepositoryListModuleActions
  extends ActionTree<RepositoryListModuleState, RootState> {
  [RepoListActions.FETCH_REPOSITORY_LIST](
    this: Store<RootState>,
    { commit }: RepositoryListActionContext,
    payload: {
      login: string
      provider: string
      limit: number
      currentPageNumber: number
      query: string
      refetch?: boolean
    }
  ): Promise<void>
  [RepoListActions.FETCH_PENDING_ADHOC_REPOSITORY_LIST](
    this: Store<RootState>,
    { commit }: RepositoryListActionContext,
    payload: { login: string; provider: string; refetch?: boolean }
  ): Promise<void>
  [RepoListActions.FETCH_NEW_REPOSITORY_LIST](
    this: Store<RootState>,
    { commit }: RepositoryListActionContext,
    payload: {
      login: string
      provider: string
      limit: number
      currentPageNumber: number
      query: string
    }
  ): Promise<void>
  [RepoListActions.FETCH_ACTIVE_REPOSITORY_LIST](
    this: Store<RootState>,
    { commit }: RepositoryListActionContext,
    payload: { login: string; provider: string; limit?: number; refetch?: boolean }
  ): Promise<void>
  [RepoListActions.FETCH_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS](
    this: Store<RootState>,
    { commit }: RepositoryListActionContext,
    payload: { login: string; provider: string; limit?: number; refetch?: boolean }
  ): Promise<void>
}

const repositoryListStoreModule: Module<RepositoryListModuleState, RootState> = {
  state: (): RepositoryListModuleState => ({
    loading: false,
    error: {},
    repositoryList: {
      pageInfo: {} as PageInfo,
      totalCount: 0 as Maybe<Scalars['Int']>,
      edges: []
    },
    newRepos: {
      pageInfo: {} as PageInfo,
      totalCount: 0 as Maybe<Scalars['Int']>,
      edges: []
    },
    repoWithActiveAnalysis: [],
    repoWithActiveAnalysisWithAnalyzers: [],
    repoWithPendingAdhocRuns: []
  }),

  mutations: {
    [RepoListMutations.SET_LOADING](state, value) {
      state.loading = value
    },
    [RepoListMutations.SET_ERROR](state, error) {
      state.error = Object.assign({}, state.error, error)
    },
    [RepoListMutations.SET_REPOSITORY_LIST](state, repositoryList) {
      state.repositoryList = Object.assign({}, state.repositoryList, repositoryList)
    },
    [RepoListMutations.SET_NEW_REPOSITORY_LIST](state, repositoryList) {
      state.newRepos = Object.assign({}, state.repositoryList, repositoryList)
    },
    [RepoListMutations.SET_ACTIVE_REPOSITORY_LIST](state, repositoryList) {
      state.repoWithActiveAnalysis = resolveNodes(repositoryList)
    },
    [RepoListMutations.SET_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS](state, repositoryList) {
      state.repoWithActiveAnalysisWithAnalyzers = resolveNodes(repositoryList)
    },
    [RepoListMutations.SET_ADHOC_RUN_REPOS](state, repoConnect) {
      state.repoWithPendingAdhocRuns = resolveNodes(repoConnect)
    }
  } as RepositoryListModuleMutations,

  actions: {
    async [RepoListActions.FETCH_REPOSITORY_LIST]({ commit }, payload) {
      commit(RepoListMutations.SET_LOADING, true)
      try {
        const response: { data: { owner: Owner } } = await this.$fetchGraphqlData(
          RepositoryListGQLQuery,
          {
            login: payload.login,
            provider: this.$providerMetaMap[payload.provider].value,
            isActivated: true,
            limit: payload.limit,
            after: this.$getGQLAfter(payload.currentPageNumber, payload.limit),
            query: payload.query
          },
          payload.refetch
        )

        commit(RepoListMutations.SET_REPOSITORY_LIST, response.data.owner.repositories)
        commit(RepoListMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(RepoListMutations.SET_ERROR, error)
        commit(RepoListMutations.SET_LOADING, false)
      }
    },
    async [RepoListActions.FETCH_PENDING_ADHOC_REPOSITORY_LIST]({ commit }, payload) {
      try {
        const response = await this.$fetchGraphqlData(
          RepositoryAdHocPending,
          {
            login: payload.login,
            provider: this.$providerMetaMap[payload.provider].value
          },
          payload.refetch
        )
        commit(RepoListMutations.SET_ADHOC_RUN_REPOS, response.data.owner.repositories)
      } catch (e) {
        const error = e as GraphqlError
        commit(RepoListMutations.SET_ERROR, error)
      }
    },
    async [RepoListActions.FETCH_NEW_REPOSITORY_LIST]({ commit }, payload) {
      commit(RepoListMutations.SET_LOADING, true)
      try {
        const response: { data: { owner: Owner } } = await this.$fetchGraphqlData(
          RepositoryListGQLQuery,
          {
            login: payload.login,
            provider: this.$providerMetaMap[payload.provider].value,
            isActivated: false,
            limit: payload.limit,
            after: this.$getGQLAfter(payload.currentPageNumber, payload.limit),
            query: payload.query
          },
          true
        )

        commit(RepoListMutations.SET_NEW_REPOSITORY_LIST, response.data.owner.repositories)
        commit(RepoListMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(RepoListMutations.SET_ERROR, error)
        commit(RepoListMutations.SET_LOADING, false)
      }
    },
    async [RepoListActions.FETCH_ACTIVE_REPOSITORY_LIST]({ commit }, payload) {
      commit(RepoListMutations.SET_LOADING, true)
      try {
        const response: { data: { owner: Owner } } = await this.$fetchGraphqlData(
          RecentlyActiveRepoList,
          {
            login: payload.login,
            provider: this.$providerMetaMap[payload.provider].value,
            limit: payload.limit ?? 5,
            fetchAnalyzers: false
          },
          payload.refetch
        )

        commit(RepoListMutations.SET_ACTIVE_REPOSITORY_LIST, response.data.owner.repositories)
        commit(RepoListMutations.SET_LOADING, false)
      } catch (e) {
        const error = e as GraphqlError
        commit(RepoListMutations.SET_ERROR, error)
        commit(RepoListMutations.SET_LOADING, false)
      }
    },
    async [RepoListActions.FETCH_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS]({ commit }, payload) {
      try {
        const response: { data: { owner: Owner } } = await this.$fetchGraphqlData(
          RecentlyActiveRepoList,
          {
            login: payload.login,
            provider: this.$providerMetaMap[payload.provider].value,
            limit: payload.limit ?? 10,
            fetchAnalyzers: true
          },
          payload.refetch
        )

        commit(
          RepoListMutations.SET_ACTIVE_REPOSITORY_LIST_WITH_ANALYZERS,
          response.data.owner.repositories
        )
      } catch (e) {
        const error = e as GraphqlError
        commit(RepoListMutations.SET_ERROR, error)
      }
    }
  } as RepositoryListModuleActions
}

export default repositoryListStoreModule
